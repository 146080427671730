import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import CustomDatePicker from "./DatePicker";
import dayjs from "dayjs";
import { postData } from "../../utils/axiosCall";
import { numberMapping } from "../../utils/mapping";
import { useEntireAsset } from "../../hooks/useEntireAsset";
import { useMasterEmployeeData } from "../../hooks/useMasterEmployeeData";
import { useMaintenanceData } from "../../hooks/useMaintenanceData";
import { useRiskData } from "../../hooks/useRiskData";
import { useGetMasterEmployee } from "../../hooks/useGetMasterEmployee";

const ModalAdd = ({
  data,
  modalOpen,
  closeModal,
  updateStatus,
  setUpdateStatus,
}) => {
  const location = useLocation();
  const { assetCodeSet } = useEntireAsset();
  const { team, site } = useMasterEmployeeData();
  const { planData } = useMaintenanceData();
  const { factor } = useRiskData();
  const { employeeData } = useGetMasterEmployee();

  const employeeSet = [
    ...new Set(employeeData.map((item) => item.employee_id)),
  ];
  const teamSet = [...new Set(team.map((item) => item.team_name))];
  const siteSet = [...new Set(site.map((item) => item.site_name))];
  const factorSet = [...new Set(factor.map((item) => item.risk_factor_code))];
  const planDataSet = [...new Set(planData.map((item) => item.ma_plan_id))];

  const initialData = data
    .filter((item) => {
      const commonConditions =
        !["update_date", "select-col", "risk_measure_id"].includes(item.id) &&
        !["No.", "메뉴얼", "머신ID", "설비상태", "점검 대상 이미지"].includes(
          item.header
        );
      const additionalCondition =
        location.pathname !== "/plan" ||
        (item.header !== "사내작업 공수" &&
          item.header !== "계획 소요시간(Hr)");
      return commonConditions && additionalCondition;
    })
    .map((item) => ({
      id: item.id,
      name: item.id,
      header: item.header,
      value: "",
    }));

  const [inputValues, setInputValues] = useState(initialData);

  const handleInputChange = (name, value) => {
    setInputValues((prev) =>
      prev.map((item) => (item.name === name ? { ...item, value } : item))
    );
  };

  const onClickSubmit = () => {
    const param = getParamByPathname(location.pathname);
    let val = inputValues.map(({ id, value }) => ({
      id,
      value: value === "" ? null : value,
    }));

    if (location.pathname === "/employee") {
      val = val.map((obj) => {
        if (obj.id === "team_name") {
          const teamObj = team.find((t) => t.team_name === obj.value);
          return teamObj ? { ...obj, value: teamObj.team_id } : obj;
        } else if (obj.id === "site_name") {
          const siteObj = site.find((s) => s.site_name === obj.value);
          return siteObj ? { ...obj, value: siteObj.site_id } : obj;
        }
        return obj;
      });
    }

    postData(val, param, closeModal, updateStatus, setUpdateStatus);
    closeModal();
  };

  const renderInputField = (item, index) => {
    if (
      [
        "구입일자",
        "제작일자",
        "개선 예정일",
        "개선 완료일",
        "평가일자",
        "계획 일정",
        "입사일자",
      ].includes(item.header)
    ) {
      return (
        <CustomDatePicker
          label={item.header}
          name={item.id}
          onChange={(newValue) =>
            handleInputChange(item.id, dayjs(newValue).format("YYYY-MM-DD"))
          }
        />
      );
    }

    if (
      [
        "site_name",
        "team_name",
        "risk_factor_code",
        "employee_id",
        "ma_plan_id",
      ].includes(item.id) ||
      (item.header === "설비식별코드(관리번호)" &&
        location.pathname !== "/asset") ||
      item.header === "설비식별코드" ||
      (item.header === "설비코드" && location.pathname !== "/asset")
    ) {
      const options = getOptionsForField(item.id);
      return (
        <TextField
          select
          sx={{
            width: "206px",
            "& .MuiOutlinedInput-root": {
              fontSize: "1rem",
              padding: "0rem 1.7rem",
            },
            "& .MuiSelect-root": { padding: "1rem" },
          }}
          label={item.header}
          name={item.id}
          margin="dense"
          value={inputValues[index].value}
          onChange={(e) => handleInputChange(item.id, e.target.value)}
          SelectProps={{ native: true }}
        >
          <option value=""></option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      );
    }
    return (
      <TextField
        size="normal"
        label={item.header}
        name={item.id}
        margin="dense"
        type={numberMapping.includes(item.id) ? "number" : "text"}
        onChange={(e) => handleInputChange(item.id, e.target.value)}
        value={inputValues[index].value}
      />
    );
  };
  const getOptionsForField = (fieldId) => {
    switch (fieldId) {
      case "site_name":
        return siteSet;
      case "team_name":
        return teamSet;
      case "risk_factor_code":
        return factorSet;
      case "employee_id":
        return employeeSet;
      case "ma_plan_id":
        return planDataSet;
      default:
        return assetCodeSet;
    }
  };
  const getParamByPathname = (pathname) => {
    const pathToParam = {
      "/asset": "postMasterAsset",
      "/rating": "postRatingAsset",
      "/daily": "postInspectionDaily",
      "/periodic": "postInspectionPeriodic",
      "/factor": "postRiskFactor",
      "/plan": "postMaintenancePlan",
      "/employee": "postMasterEmployee",
      "/planSet": "postMasterPlanSet",
      "/register": "postRiskRegister",
    };
    return pathToParam[pathname] || "null";
  };
  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      <DialogTitle>
        <strong>추가</strong>
      </DialogTitle>
      <DialogContent className="modalEditDialog">
        {initialData.map((item, i) => (
          <div key={i} style={{ width: "47%", textAlign: "center" }}>
            {renderInputField(item, i)}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="success" onClick={onClickSubmit}>
          추가
        </Button>
        <Button variant="outlined" color="error" onClick={closeModal}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAdd;
