// import React, { useState, useEffect, useRef } from "react";
// import * as s from "./styles";
// import Card from "./Card";
// import Chart from "../Graph";
// import Content from "./Card/Content";
// import { useRiskMaintenance } from "../../hooks/useRiskMaintenance";
// import { useMaintenanceResultExpenseData } from "../../hooks/useMaintenanceResultExpenseData";
// import { useMasterAssetDataForGraph } from "../../hooks/useMasterAssetDataForGraph";
// import { useBrokenData } from "../../hooks/useBrokenData";
// import { useDataInspection } from "../../hooks/useDataInspection";
// import ContentTop3 from "./Card/Content/ContentTop3";
// import { useDispatch, useSelector } from "react-redux";
// import Productivity from "./Card/Content/ContentProductivity";
// import { useProductivityData } from "../../hooks/useProductivityData";
// import { Overlay, ChartBox, CloseBtn, Dim } from "../Graph/styles";
// import ECharts from "echarts-for-react";
// import { useGetPreventScrolling } from "../../hooks/useGetPreventScrolling";
// import { setPopStatus, setPopShowing } from "../../slice/authSlice";
// import { useFusionMatrix } from "../../hooks/useFusionMatrix";
// import { useWorkerData } from "../../hooks/useWorkerData";
// import { useFailureData } from "../../hooks/useFailureData";
// import Fusion from "./Card/Content/Fusion";
// // const cards = [
// //   { id: 1, content: "설비 노후화율" },
// //   { id: 2, content: "고장도수율" },
// //   { id: 3, content: "TOP3" },
// //   { id: 4, content: "일상점검율" },
// //   { id: 5, content: "보전계획준수율" },
// //   { id: 6, content: "위험성평가 총건수" },
// //   { id: 7, content: "생산성" },
// // ];

// const cards = [
//   { id: 1, content: "생산성" },
//   { id: 2, content: "작업자" },
//   { id: 3, content: "불량" },
//   { id: 4, content: "위험성평가 총건수" },
//   { id: 5, content: "설비 노후화율" },
//   { id: 6, content: "고장도수율" },
//   { id: 7, content: "TOP3" },
//   { id: 8, content: "일상점검율" },
//   { id: 9, content: "보전계획준수율" },
//   { id: 10, content: "융합" },
// ];
// const fusionData = [
//   {
//     id: 1,
//     label1: "설비사용율",
//     color1: "#E8FFE6",
//     label2: "설비불량율",
//     color2: "#E0F8FF",
//   },
//   {
//     id: 2,
//     label1: "설비가동율",
//     color1: "#E8FFE6",
//     label2: "UPH",
//     color2: "#E0F8FF",
//   },
//   {
//     id: 3,
//     label1: "일상점검율",
//     color1: "#E8FFE6",
//     label2: "고장율",
//     color2: "#E0F8FF",
//   },
//   {
//     id: 4,
//     label1: "위험지수",
//     color1: "#E8FFE6",
//     label2: "UPH",
//     color2: "#E0F8FF",
//   },
// ];

// function CarouselTotal({ sendMessage }) {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedCardIndex, setSelectedCardIndex] = useState(null);
//   const [carouselVisible, setCarouselVisible] = useState(true);
//   const [animating, setAnimating] = useState(false);
//   const [autoSlideTimer, setAutoSlideTimer] = useState(null);
//   const cardsRef = useRef(null);
//   const dispatch = useDispatch();
//   const { chattingStatus } = useSelector((state) => state.auth);
//   const isUnityChattingOpend =
//     chattingStatus.type === "Meta" && chattingStatus.status === "1";
//   useEffect(() => {
//     const dividedNumber = isUnityChattingOpend ? 2 : 3;
//     if (cardsRef.current) {
//       cardsRef.current.style.transform = `translateX(-${
//         currentIndex * (cardsRef.current.offsetWidth / dividedNumber)
//       }px)`;
//     }
//   }, [currentIndex, carouselVisible]);

//   const handlePopOut = () => {
//     dispatch(setPopShowing(""));
//     dispatch(setPopStatus(false));
//   };

//   const handleEChartsEvent = {
//     click: handlePopOut,
//   };

//   const graphStatus = useSelector((state) => state.auth.graphStatus);
//   const popShowing = useSelector((state) => state.auth.popShowing);
//   const popStatus = useSelector((state) => state.auth.popStatus);

//   const handleCardClick = (index) => {
//     setAnimating(true);
//     setSelectedCardIndex(index);
//     setCarouselVisible(false);
//     setTimeout(() => {
//       setAnimating(false);
//     }, 500);
//   };
//   useEffect(() => {
//     if (selectedCardIndex === null) {
//       const timer = setTimeout(() => {
//         nextCard();
//       }, 10000);
//       setAutoSlideTimer(timer);
//       return () => clearTimeout(timer);
//     }
//   }, [currentIndex, selectedCardIndex]);
//   const prevCard = () => {
//     if (selectedCardIndex !== null) {
//       setSelectedCardIndex((prev) =>
//         prev.id === 1 ? cards[cards.length - 1] : cards[prev.id - 2]
//       );
//     } else {
//       const unityAdjustNumber = isUnityChattingOpend ? 1 : 0;
//       setCurrentIndex((prevIndex) =>
//         prevIndex === 0 ? cards.length - 3 + unityAdjustNumber : prevIndex - 1
//       );
//     }
//     clearTimeout(autoSlideTimer);
//   };
//   const nextCard = () => {
//     if (selectedCardIndex !== null) {
//       setSelectedCardIndex((prev) =>
//         prev.id === cards.length ? cards[0] : cards[prev.id]
//       );
//     } else {
//       const unityAdjustNumber = isUnityChattingOpend ? 1 : 0;
//       setCurrentIndex((prevIndex) =>
//         prevIndex === cards.length - 3 + unityAdjustNumber ? 0 : prevIndex + 1
//       );
//     }
//     clearTimeout(autoSlideTimer);
//   };
//   const { riskTitle, riskbig1, riskbig2, riskbig3, riskbig4 } =
//     useRiskMaintenance();
//   const { titleConfig, expenseBig1, expenseBig2, expenseBig3, expenseBig4 } =
//     useMaintenanceResultExpenseData();
//   const { oldTitleConfig, bigOld1, bigOld2, bigOld3 } =
//     useMasterAssetDataForGraph();
//   const {
//     brokenTitleConfig,
//     brokenTitleConfig2,
//     topBig1,
//     topBig2,
//     topBig3,
//     brokenBig1,
//     brokenBig3,
//     brokenBig4,
//     brokenBig5,
//   } = useBrokenData();
//   const {
//     inspectionTitle,
//     inspectionBig1,
//     inspectionBig2,
//     inspectionBig3,
//     inspectionBig4,
//   } = useDataInspection();
//   const { workerTitle, workerBig1, workerBig2, workerBig3, workerBig4 } =
//     useWorkerData();
//   const { productivityTitle, proBig1, proBig3, proBig2, proBig4 } =
//     useProductivityData();
//   const { failBig1, failBig2, failBig3, failBig4, failTitle } =
//     useFailureData();
//   const { fusionBig1, fusionBig2, fusionBig3, fusionBig4 } = useFusionMatrix();
//   const chartOptionsMapping = {
//     produictive1: proBig1,
//     produictive2: proBig3,
//     produictive3: proBig2,
//     produictive4: proBig4,
//     inspection1: inspectionBig1,
//     inspection2: inspectionBig2,
//     inspection3: inspectionBig3,
//     inspection4: inspectionBig4,
//     top31: topBig1,
//     top32: topBig2,
//     top33: topBig3,
//     broken1: brokenBig1,
//     broken3: brokenBig3,
//     broken4: brokenBig4,
//     broken5: brokenBig5,
//     old1: bigOld1,
//     old2: bigOld2,
//     old3: bigOld3,
//     risk1: riskbig1,
//     risk2: riskbig2,
//     risk3: riskbig3,
//     risk4: riskbig4,
//     expense1: expenseBig1,
//     expense2: expenseBig2,
//     expense3: expenseBig3,
//     expense4: expenseBig4,
//     worker1: workerBig1,
//     worker2: workerBig2,
//     worker3: workerBig3,
//     worker4: workerBig4,
//     fail1: failBig1,
//     fail2: failBig2,
//     fail3: failBig3,
//     fail4: failBig4,
//     fusion1: fusionBig1,
//     fusion2: fusionBig2,
//     fusion3: fusionBig3,
//     fusion4: fusionBig4,
//   };
//   useGetPreventScrolling();
//   const handleSelectedCardClick = () => {
//     const idx = selectedCardIndex.id - 1 > 4 ? 4 : selectedCardIndex.id - 1;
//     setCurrentIndex(idx);
//     setSelectedCardIndex(null);
//     setCarouselVisible(true);
//     setAnimating(true);
//     setTimeout(() => {
//       setAnimating(false);
//     }, 500);
//   };
//   const onCloseDim = (e) => {
//     dispatch(setPopShowing(""));
//     dispatch(setPopStatus(false));
//     e.preventDefault();
//   };
//   const handleOverlayClick = (e) => {
//     e.stopPropagation();
//   };
//   return (
//     <>
//       {graphStatus && (
//         <s.CarouselContainer $isUnityChattingOpend={isUnityChattingOpend}>
//           {selectedCardIndex !== null && !animating ? (
//             <>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   width: "550px",
//                   height: "100px",
//                   position: "absolute",
//                   top: "-400px",
//                   left: "-20px",
//                 }}
//               >
//                 <s.PrevBtnOutside onClick={prevCard}>&#8249;</s.PrevBtnOutside>
//                 <s.SelectedCard
//                   onClick={() => handleSelectedCardClick()}
//                   $carouselVisible={carouselVisible}
//                   className={
//                     carouselVisible
//                       ? "selected-card-hidden"
//                       : "selected-card-visible"
//                   }
//                 >
//                   {cards[selectedCardIndex?.id - 1].content ===
//                     "설비 노후화율" && (
//                     <Content
//                       data={oldTitleConfig}
//                       $labelSize="1.3rem"
//                       $valueSize="2.0rem"
//                       $4KlabelSize="2rem"
//                       $4KvalueSize="4rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content ===
//                     "고장도수율" && (
//                     <Content
//                       data={brokenTitleConfig}
//                       $labelSize="1rem"
//                       $valueSize="1.7rem"
//                       $4KlabelSize="2rem"
//                       $4KvalueSize="4rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content === "TOP3" && (
//                     <ContentTop3
//                       initial={brokenTitleConfig2}
//                       $labelSize="1.4rem"
//                       $valueSize="0.62rem"
//                       $4KlabelSize="2.5rem"
//                       $4KvalueSize="1.8rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content ===
//                     "일상점검율" && (
//                     <Content
//                       data={inspectionTitle}
//                       $labelSize="0.8rem"
//                       $valueSize="1.5rem"
//                       $4KlabelSize="2rem"
//                       $4KvalueSize="4rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content ===
//                     "보전계획준수율" && (
//                     <Content
//                       data={titleConfig}
//                       $labelSize="0.68rem"
//                       $valueSize="1.4rem"
//                       $4KlabelSize="1.6rem"
//                       $4KvalueSize="3.8rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content ===
//                     "위험성평가 총건수" && (
//                     <Content
//                       data={riskTitle}
//                       $labelSize="0.87rem"
//                       $valueSize="1.6rem"
//                       $4KlabelSize="2rem"
//                       $4KvalueSize="4rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content === "생산성" && (
//                     <Productivity
//                       data={productivityTitle}
//                       $labelSize="0.7rem"
//                       $valueSize="1.4rem"
//                       $4KlabelSize="2rem"
//                       $4KvalueSize="4rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content === "작업자" && (
//                     <Content
//                       data={workerTitle}
//                       $labelSize="1.2rem"
//                       $valueSize="1.7rem"
//                       $4KlabelSize="2rem"
//                       $4KvalueSize="4rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content === "불량" && (
//                     <Content
//                       data={failTitle}
//                       $labelSize="0.9rem"
//                       $valueSize="1.5rem"
//                       $4KlabelSize="2rem"
//                       $4KvalueSize="4rem"
//                     />
//                   )}
//                   {cards[selectedCardIndex?.id - 1].content === "융합" && (
//                     <Fusion data={fusionData} />
//                   )}
//                 </s.SelectedCard>
//                 <s.NextBtnOutside onClick={nextCard}>&#8250;</s.NextBtnOutside>
//               </div>
//               <s.RedBox
//                 $isUnityChattingOpend={isUnityChattingOpend}
//                 $carouselVisible={carouselVisible}
//                 className={
//                   carouselVisible ? "red-box-hidden" : "red-box-visible"
//                 }
//               >
//                 <Chart
//                   index={cards[selectedCardIndex?.id - 1].content}
//                   sendMessage={sendMessage}
//                 />
//               </s.RedBox>
//             </>
//           ) : (
//             <s.StyledCarousel
//               $carouselVisible={carouselVisible}
//               $animating={animating}
//               className={
//                 carouselVisible
//                   ? "carousel-visible"
//                   : animating
//                   ? "carousel-hiding"
//                   : "carousel-hidden"
//               }
//               $isUnityChattingOpend={isUnityChattingOpend}
//             >
//               <s.PrevBtn onClick={prevCard}>&#8249;</s.PrevBtn>
//               <Card
//                 cardsRef={cardsRef}
//                 cards={cards}
//                 handleCardClick={handleCardClick}
//               />
//               <s.NextBtn onClick={nextCard}>&#8250;</s.NextBtn>
//             </s.StyledCarousel>
//           )}
//         </s.CarouselContainer>
//       )}
//       {popStatus && (
//         <Dim onClick={onCloseDim}>
//           <Overlay onClick={handleOverlayClick}>
//             <CloseBtn onClick={handlePopOut}>X</CloseBtn>
//             <ChartBox>
//               <ECharts
//                 option={chartOptionsMapping[popShowing]}
//                 opts={{
//                   renderer: "svg",
//                   width: "1500px",
//                   height: "660px",
//                 }}
//               />
//             </ChartBox>
//           </Overlay>
//         </Dim>
//       )}
//     </>
//   );
// }

// export default CarouselTotal;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as s from "./styles";
import { useGetPreventScrolling } from "../../hooks/useGetPreventScrolling";
import { useChartData } from "../../hooks/useChartData";
import { useCarouselLogic } from "../../hooks/useCarouselLogic";
import SelectedCard from "./SelectedCard";
import PopupChart from "./PopupChart";
import CarouselCard from "./CarouselCard";
import { cards, fusionData } from "./constants";
import { setPopShowing, setPopStatus } from "../../slice/authSlice";

function CarouselTotal({ sendMessage }) {
  const dispatch = useDispatch();
  const { graphStatus, popStatus, popShowing, chattingStatus } = useSelector(
    (state) => state.auth
  );
  const isUnityChattingOpened =
    chattingStatus.type === "Meta" && chattingStatus.status === "1";
  const {
    currentIndex,
    selectedCardIndex,
    carouselVisible,
    animating,
    cardsRef,
    handleCardClick,
    handleSelectedCardClick,
    prevCard,
    nextCard,
  } = useCarouselLogic(isUnityChattingOpened);
  const chartData = useChartData();
  useGetPreventScrolling();
  if (!graphStatus) return null;
  const onCloseDim = (e) => {
    dispatch(setPopShowing(""));
    dispatch(setPopStatus(false));
    e.preventDefault();
  };
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  const handlePopOut = () => {
    dispatch(setPopShowing(""));
    dispatch(setPopStatus(false));
  };

  const handleEChartsEvent = {
    click: handlePopOut,
  };

  return (
    <>
      <s.CarouselContainer $isUnityChattingOpened={isUnityChattingOpened}>
        {selectedCardIndex !== null && !animating ? (
          <SelectedCard
            selectedCardIndex={selectedCardIndex}
            carouselVisible={carouselVisible}
            handleSelectedCardClick={handleSelectedCardClick}
            prevCard={prevCard}
            nextCard={nextCard}
            isUnityChattingOpened={isUnityChattingOpened}
            chartData={chartData}
            sendMessage={sendMessage}
            fusionData={fusionData}
          />
        ) : (
          <CarouselCard
            cardsRef={cardsRef}
            currentIndex={currentIndex}
            carouselVisible={carouselVisible}
            animating={animating}
            isUnityChattingOpened={isUnityChattingOpened}
            handleCardClick={handleCardClick}
            prevCard={prevCard}
            nextCard={nextCard}
            cards={cards}
          />
        )}
      </s.CarouselContainer>
      {popStatus && (
        <PopupChart
          onCloseDim={onCloseDim}
          handleOverlayClick={handleOverlayClick}
          handlePopOut={handlePopOut}
          popShowing={popShowing}
        />
      )}
    </>
  );
}

export default CarouselTotal;
