import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import CustomDatePicker from "./DatePicker";
import dayjs from "dayjs";
import { putData } from "../../utils/axiosCall";
import { numberMapping } from "../../utils/mapping";
import { useEntireAsset } from "../../hooks/useEntireAsset";
import { useMasterEmployeeData } from "../../hooks/useMasterEmployeeData";
import { useMaintenanceData } from "../../hooks/useMaintenanceData";
import { useRiskData } from "../../hooks/useRiskData";
import { useGetMasterEmployee } from "../../hooks/useGetMasterEmployee";

function ModalEdit({
  data,
  modalOpen,
  closeModal,
  updateStatus,
  setUpdateStatus,
}) {
  const location = useLocation();
  const { assetCodeSet } = useEntireAsset();
  const { team, site } = useMasterEmployeeData();
  const { factor } = useRiskData();
  const { planData } = useMaintenanceData();
  const { employeeData } = useGetMasterEmployee();

  const employeeSet = [
    ...new Set(employeeData.map((item) => item.employee_id)),
  ];
  const factorSet = [...new Set(factor.map((item) => item.risk_factor_code))];
  const planDataSet = [...new Set(planData.map((item) => item.ma_plan_id))];
  const teamSet = [...new Set(team.map((item) => item.team_name))];
  const siteSet = [...new Set(site.map((item) => item.site_name))];

  const initialData = data
    .filter(
      (item) =>
        ![
          "update_date",
          "No.",
          "메뉴얼",
          "머신ID",
          "설비상태",
          "점검 대상 이미지",
          "select-col",
          "risk_measure_id",
        ].includes(item.id) &&
        !["No.", "메뉴얼", "머신ID", "설비상태", "점검 대상 이미지"].includes(
          item.header
        )
    )
    .map((item) => ({ ...item, name: item.id }));

  const [inputValues, setInputValues] = useState(initialData);

  const handleInputChange = (index, value) => {
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index].value = value;
      return newValues;
    });
  };

  const handleDateChange = (name, newValue) => {
    const formattedValue = newValue
      ? dayjs(newValue).format("YYYY-MM-DD")
      : null;
    handleInputChange(
      inputValues.findIndex((item) => item.name === name),
      formattedValue
    );
  };

  const handleSubmit = () => {
    let val = inputValues.map(({ id, value }) => ({ id, value }));

    if (["/daily", "/periodic"].includes(location.pathname)) {
      const element = data.find((item) => item.header === "No.");
      val.push(element);
    }

    if (location.pathname === "/plan") {
      val = val.map((item) =>
        item.id === "ma_plan_schedule" && item.value.length === 7
          ? { ...item, value: item.value + "-01" }
          : item
      );
    }

    if (location.pathname === "/register") {
      const measureValue = data.find(
        (item) => item.id === "risk_measure_id"
      ).value;
      val = val
        .map((item) =>
          [
            "risk_measure_expect",
            "risk_measure_end",
            "risk_register_date",
          ].includes(item.id) &&
          item.value.length === 7 &&
          item.value !== "0000-00"
            ? { ...item, value: item.value + "-01" }
            : item
        )
        .concat({ id: "risk_measure_id", value: measureValue });
    }

    if (location.pathname === "/employee") {
      val = val.map((obj) => {
        if (obj.id === "team_name") {
          const teamObj = team.find((t) => t.team_name === obj.value);
          return teamObj ? { ...obj, value: teamObj.team_id } : obj;
        } else if (obj.id === "site_name") {
          const siteObj = site.find((s) => s.site_name === obj.value);
          return siteObj ? { ...obj, value: siteObj.site_id } : obj;
        }
        return obj;
      });
    }

    val = val.map((obj) =>
      obj.value === "" || obj.value === "0000-00"
        ? { ...obj, value: null }
        : obj
    );

    const param =
      {
        "/asset": "putMasterAsset",
        "/rating": "putRatingAsset",
        "/daily": "putInspectionDaily",
        "/periodic": "putInspectionPeriodic",
        "/factor": "putRiskFactor",
        "/plan": "putMaintenancePlan",
        "/employee": "putMasterEmployee",
        "/register": "putRiskRegister",
      }[location.pathname] || "null";

    putData(val, param, closeModal, updateStatus, setUpdateStatus);
    closeModal();
  };

  const renderInput = (item, index) => {
    const commonProps = {
      label: item.header,
      name: item.id,
      margin: "dense",
      value: inputValues[index].value ?? "",
      onChange: (e) => handleInputChange(index, e.target.value),
    };

    if (
      ["구입일자", "제작일자", "평가일자", "계획 일정", "입사일자"].includes(
        item.header
      ) ||
      [
        "risk_measure_expect",
        "risk_measure_end",
        "risk_register_date",
      ].includes(item.id)
    ) {
      return (
        <CustomDatePicker
          {...commonProps}
          value={
            inputValues[index].value === "0000-00-00" ||
            inputValues[index].value === "0000-00"
              ? dayjs("2026-01-01")
              : dayjs(inputValues[index].value)
          }
          onChange={(newValue) => handleDateChange(item.id, newValue)}
        />
      );
    }

    const selectOptions = {
      employee_id: employeeSet,
      site_name: siteSet,
      risk_factor_code: factorSet,
      team_name: teamSet,
      asset_code: assetCodeSet,
    };

    if (
      selectOptions[item.id] ||
      (["설비식별코드(관리번호)", "설비식별코드", "설비코드"].includes(
        item.header
      ) &&
        location.pathname !== "/asset")
    ) {
      return (
        <TextField
          select
          {...commonProps}
          SelectProps={{ native: true }}
          sx={{
            width: "206px",
            "& .MuiOutlinedInput-root": {
              fontSize: "1rem",
              padding: "0rem 1.7rem",
            },
            "& .MuiSelect-root": { padding: "1rem" },
          }}
        >
          <option value=""></option>
          {(selectOptions[item.id] || assetCodeSet).map((code) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </TextField>
      );
    }

    return (
      <TextField
        {...commonProps}
        type={numberMapping.includes(item.id) ? "number" : "text"}
        disabled={
          (item.name === "risk_factor_code" &&
            location.pathname !== "/register") ||
          (location.pathname === "plan" && item.name === "ma_plan_id") ||
          (item.name === "employee_id" && location.pathname !== "/register") ||
          item.name === "ma_planset_id"
        }
      />
    );
  };

  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      <DialogTitle>
        <strong>수정</strong>
      </DialogTitle>
      <DialogContent className="modalEditDialog">
        {initialData.map((item, i) => (
          <div key={i} style={{ width: "47%", textAlign: "center" }}>
            {renderInput(item, i)}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="success" onClick={handleSubmit}>
          수정
        </Button>
        <Button variant="outlined" color="error" onClick={closeModal}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalEdit;
