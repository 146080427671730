import React from "react";
import * as s from "../styles";
function Top3({ initial, $4KlabelSize, $4KvalueSize, $valueSize, $labelSize }) {
  return (
    <s.Container>
      {initial?.map((item, idx) => (
        <s.Top3DataContainer key={item.id}>
          <s.Label $labelSize={$labelSize} $4KlabelSize={$4KlabelSize}>
            {item.label}
          </s.Label>
          <s.Top3Box>
            {item.data.map((item, i) => {
              const [name, value] = item.split(" - ");
              return (
                <s.Top3Value
                  $valueSize={$valueSize}
                  $4KvalueSize={$4KvalueSize}
                  key={i}
                >
                  <span>{name}</span>
                  <span>{value}%</span>
                </s.Top3Value>
              );
            })}
          </s.Top3Box>
        </s.Top3DataContainer>
      ))}
    </s.Container>
  );
}

export default Top3;
