import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import FactoryIcon from "@mui/icons-material/Factory";
import ConstructionIcon from "@mui/icons-material/Construction";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { setKpiStatus, setGraphStatus } from "../../slice/authSlice";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import * as s from "./styles";

function SideBar({ unload }) {
  const [collapsed, setCollapsed] = useState(true);
  const { logout } = useContext(AuthContext);
  const graphStatus = useSelector((state) => state.auth.graphStatus);
  const kpiStatus = useSelector((state) => state.auth.kpiStatus);
  const dispatch = useDispatch();
  const handleGraphStatus = () => {
    dispatch(setGraphStatus(!graphStatus));
  };
  const handleKpiCardStatus = () => {
    dispatch(setKpiStatus(!kpiStatus));
  };
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    if (unload) {
      await unload();
    }
    logout();
    window.location.reload();
  };
  const handleLinkClick = async (path) => {
    if (location.pathname === "/sfv") {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  };
  return (
    <Sidebar
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
        height: "100vh",
      }}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "#1a1a1a !important",
          color: "#a0aec0 !important",
        },
        [`.${menuClasses.menuItemRoot}`]: {
          backgroundColor: "#1a1a1a !important",
          color: "#a0aec0 !important",
        },
        [`.${menuClasses.button}`]: {
          "&:hover": {
            color: "#f7fafc !important",
            background: "#343a40 !important",
          },
        },
      }}
      collapsed={collapsed}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
    >
      <s.TopWrapper>
        <s.TopLogoBox>
          <img
            className="Sidebar_logo"
            src="/images/ese_logo.png"
            alt="logo"
            style={{ width: collapsed ? "60px" : "180px" }}
          />
        </s.TopLogoBox>
        <Menu iconShape="square">
          <MenuItem onClick={() => handleLinkClick("/")} icon={<FactoryIcon />}>
            Insightwin
          </MenuItem>
          <SubMenu
            label="Master"
            title="Components"
            icon={<ConstructionIcon />}
          >
            <MenuItem onClick={() => handleLinkClick("/asset")}>
              설비기준정보
            </MenuItem>
          </SubMenu>
        </Menu>
      </s.TopWrapper>
      <s.BottomWrapper>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => handleKpiCardStatus()}
            icon={<LightbulbIcon />}
          >
            KPI 지표
          </MenuItem>
          <MenuItem
            onClick={() => handleGraphStatus()}
            icon={<AssessmentIcon />}
          >
            그래프
          </MenuItem>
          <MenuItem onClick={() => handleLogout()} icon={<ExitToAppIcon />}>
            로그아웃
          </MenuItem>
        </Menu>
      </s.BottomWrapper>
    </Sidebar>
  );
}

export default SideBar;
