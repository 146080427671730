import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { getTableColumns } from "../utils/getTableColumns";

export const useMasterAssetData = () => {
  const [assetData, setAssetData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(API_URL + "/data/getMasterAsset");
        setAssetData(res.data);
        setColumns(getTableColumns(Object.keys(res.data)));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
    fetchData();
  }, [updateStatus]);
  return {
    assetData,
    setAssetData,
    loading,
    columns,
    updateStatus,
    setUpdateStatus,
  };
};
