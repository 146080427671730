import { useState, useEffect } from "react";
import API_URL from "../utils/API_URL";
import axios from "axios";

export const useMasterEmployeeData = () => {
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([]);
  const [site, setSite] = useState([]);
  const [empData, setEmpData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(API_URL + "/data/getMasterEmployee");
        const teamAxios = await axios.get(API_URL + "/data/getMasterTeam");
        const siteAxios = await axios.get(API_URL + "/data/getMasterSite");
        setTeam(teamAxios.data);
        setSite(siteAxios.data);
        setEmpData(res.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return { loading, setEmpData, empData, team, site };
};
