function isWeekend(date) {
  const day = date.getDay();
  return day === 0 || day === 6; // 일요일또는토요일
}

// 2024년의 한국 공휴일 배열 생성
const holidays = [
  new Date("2024-01-01"),
  new Date("2024-02-09"),
  new Date("2024-02-10"),
  new Date("2024-02-11"),
  new Date("2024-02-12"),
  new Date("2024-03-01"),
  new Date("2024-04-10"),
  new Date("2024-05-05"),
  new Date("2024-05-06"),
  new Date("2024-05-15"),
  new Date("2024-06-06"),
];

// 주어진 날짜가 공휴일인지 여부를 확인
function isHoliday(date) {
  return holidays.some(
    (holiday) =>
      holiday.getFullYear() === date.getFullYear() &&
      holiday.getMonth() === date.getMonth() &&
      holiday.getDate() === date.getDate()
  );
}

// 2024년 1월부터 num월까지의 평일 시간을 계산
export function calculateBusinessHours(num) {
  let totalHours = 0;
  for (let month = 0; month < num; month++) {
    const daysInMonth = new Date(2024, month + 1, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(2024, month, day);
      if (!isWeekend(date) && !isHoliday(date)) {
        totalHours += 10; // 평일 1일 = 10시간
      }
    }
  }
  return totalHours;
}

export function getBusinessHoursInMonth(n) {
  let totalHours = 0;
  const year = 2024;
  const daysInMonth = new Date(year, n, 0).getDate();
  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, n - 1, day);
    if (!isWeekend(date) && !isHoliday(date)) {
      totalHours += 10;
    }
  }
  return totalHours;
}
