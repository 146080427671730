import axios from "axios";
import API_URL from "../utils/API_URL";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";

export const useFusionMatrix = () => {
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };

  const [fusionStates, setFusionStates] = useState({
    fusion1: {},
    fusion2: {},
    fusion3: {},
    fusion4: {},
    fusionBig1: {},
    fusionBig2: {},
    fusionBig3: {},
    fusionBig4: {},
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(API_URL + "/data/getDataMatrix");
        const fusionData = createFusionData(res.data, handleOverlay);
        setFusionStates(fusionData);
      } catch (error) {
        console.error("Error fetching fusion matrix data:", error);
      }
    }
    fetchData();
  }, []);

  return fusionStates;
};

function createFusionData(data, handleOverlay) {
  const fusionConfigs = [
    {
      name: "설비사용율&설비불량율",
      xField: "use_rate",
      yField: "defect_rate",
      xAxisName: "설비사용율",
      yAxisName: "설비불량율",
      xMarkLine: 24,
      yMarkLine: 1.5,
    },
    {
      name: "설비가동율&UPH",
      xField: "util_rate",
      yField: "uph",
      xAxisName: "설비가동율",
      yAxisName: "UPH",
      xMarkLine: 82,
      yMarkLine: 75,
    },
    {
      name: "일상점검율&고장율",
      xField: "daily_inspection_rate",
      yField: "broken_rate",
      xAxisName: "일상점검율",
      yAxisName: "고장율",
      xMarkLine: 98.5,
      yMarkLine: (data) =>
        (Math.max(...data.map((item) => Math.round(item.broken_rate * 100))) +
          Math.min(...data.map((item) => Math.round(item.broken_rate * 100)))) /
        2,
    },
    {
      name: "위험지수&UPH",
      xField: "danger_point",
      yField: "uph",
      xAxisName: "위험지수",
      yAxisName: "UPH",
      xMarkLine: 17,
      yMarkLine: 75,
    },
  ];

  const createSeries = (config) => {
    return data.map((item) => ({
      name: item.asset_name,
      type: "scatter",
      data: [
        [
          Math.round(item[config.xField] * 100),
          config.yField === "uph"
            ? item[config.yField]
            : (item[config.yField] * 100).toFixed(2),
        ],
      ],
    }));
  };

  const createTrueSeries = (series, config) => {
    return series.map((item) => ({
      ...item,
      markLine: {
        lineStyle: { type: "solid", color: "white" },
        data: [
          {
            name: config.yAxisName,
            yAxis:
              typeof config.yMarkLine === "function"
                ? config.yMarkLine(data)
                : config.yMarkLine,
          },
          { name: config.xAxisName, xAxis: config.xMarkLine },
        ],
      },
    }));
  };

  const createFusionOption = (config, series, isBig = false) => ({
    title: {
      text: config.name,
      left: "center",
      textStyle: {
        color: "white",
        fontWeight: "300",
        fontFamily: "NOTO SANS KOREAN",
        fontSize: isBig ? 30 : 20,
      },
    },
    label: {
      show: true,
      position: "top",
      color: "white",
      fontSize: isBig ? 20 : 10,
      formatter: (params) => params.seriesName,
    },
    toolbox: isBig
      ? {}
      : {
          feature: {
            myTool1: {
              show: true,
              title: "확대하기",
              icon: "path://M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z",
              onclick: () =>
                handleOverlay(`fusion${fusionConfigs.indexOf(config) + 1}`),
            },
          },
          itemSize: 25,
          top: 10,
          iconStyle: { color: "white" },
        },
    tooltip: {
      showDelay: 0,
      type: "cross",
      formatter: (params) => {
        if (params.componentType !== "markLine") {
          return `${params.marker}${params.seriesName} <br /> ${config.xAxisName} : ${params.data}<br /> ${config.yAxisName} : ${params.data[1]}`;
        }
        return null;
      },
      axisPointer: {
        show: true,
        type: "cross",
        lineStyle: { type: "dashed", width: 1 },
      },
    },
    xAxis: {
      splitLine: { show: false },
      type: "value",
      min:
        config.name === "일상점검율&고장율"
          ? 97
          : (value) => Math.min(...series.map((item) => item.data[0])),
      max:
        config.name === "일상점검율&고장율"
          ? 100
          : (value) => Math.max(...series.map((item) => item.data[0])),
      scale: false,
    },
    yAxis: {
      splitLine: { show: false },
      type: "value",
      min: 0,
      max:
        config.name === "설비사용율&설비불량율"
          ? (value) => Math.max(...series.map((item) => item.data[1]))
          : undefined,
    },
    grid: {
      left: "3%",
      right: "10%",
      bottom: "3%",
      containLabel: true,
      ...(isBig && { top: "10%" }),
    },
    series: createTrueSeries(series, config),
  });

  const fusionData = {};
  fusionConfigs.forEach((config, index) => {
    const series = createSeries(config);
    fusionData[`fusion${index + 1}`] = createFusionOption(config, series);
    fusionData[`fusionBig${index + 1}`] = createFusionOption(
      config,
      series,
      true
    );
  });
  return fusionData;
}
