import React, { useState, useMemo } from "react";
import * as c from "./../styles";
import SideBar from "../../SideBar";
import { assetColumns } from "../../../utils/columns";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table as BTable } from "react-bootstrap";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import API_URL from "../../../utils/API_URL";
import axios from "axios";
import { useMasterAssetData } from "../../../hooks/useMaterAssetData";
import ModalAdd from "../../Modal/ModalAdd";
import { Button } from "@mui/material";
import "../table.css";
import { deleteData } from "../../../utils/axiosCall";
import ModalEdit from "../../Modal/ModalEdit";
import CarouselTotal from "../../Sfv_Carousel";

function MasterAsset() {
  const { setAssetData, updateStatus, setUpdateStatus } = useMasterAssetData();
  const [modalOpen, setModalOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState([]);
  const handleToggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const dataQuery = useQuery({
    queryKey: ["data", updateStatus],
    queryFn: async () => {
      const res = await axios.get(API_URL + "/data/getMasterAsset");
      return res.data;
    },
    placeholderData: keepPreviousData,
  });
  const defaultData = useMemo(() => [], []);
  const table = useReactTable({
    data: dataQuery.data ?? defaultData,
    columns: assetColumns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setAssetData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    debugTable: true,
  });
  const handleCellDoubleClick = (cell) => {
    setEditData(
      assetColumns.map((item) => {
        return { ...item, value: cell.row.original[item.accessorKey] };
      })
    );
    setEditOpen(!editOpen);
  };
  const closePopup = () => {
    setEditOpen(false);
  };
  const onClickDeleteButton = () => {
    const selectedIndex = Object.keys(table.getState().rowSelection).map(
      (item) => Number(item)
    );
    const deleteArray = dataQuery.data
      .filter((item, idx) => selectedIndex.includes(idx))
      .map((item) => item.asset_code);
    deleteData(deleteArray, "deleteMasterAsset", updateStatus, setUpdateStatus);
  };
  return (
    <>
      <SideBar />
      <c.Container>
        <div style={{ display: "flex", gap: "20px" }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleToggleModal}
          >
            추가
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => onClickDeleteButton()}
            disabled={!Object.keys(table.getState().rowSelection).length}
          >
            삭제
          </Button>
        </div>
        <c.Box>
          <c.Title>설비기준정보</c.Title>
        </c.Box>
        <BTable className="t_table" striped bordered hover responsive size="sm">
          <thead className="t_thead">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="t_tr" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <c.ThElement
                    className="t_th center"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </c.ThElement>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="t_tbody">
            {table.getRowModel().rows.map((row) => (
              <tr className="t_tr" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    onDoubleClick={() => handleCellDoubleClick(cell)}
                    className="t_td"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </BTable>
      </c.Container>
      {modalOpen && (
        <ModalAdd
          closeModal={handleToggleModal}
          modalOpen
          data={assetColumns}
          updateStatus={updateStatus}
          setUpdateStatus={setUpdateStatus}
        />
      )}
      {editOpen && (
        <ModalEdit
          data={editData}
          modalOpen={editOpen}
          closeModal={closePopup}
          updateStatus={updateStatus}
          setUpdateStatus={setUpdateStatus}
        />
      )}
      <CarouselTotal />
    </>
  );
}
export default MasterAsset;
