import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";

import CustomTextField from "../../themes/overrides/CustomTextField";

import LoginWrapper from "./LoginWrapper";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// 유효성 검증 라이브러리
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAuth } from "../../hooks/useAuth";

const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "450px" },
}));

const schema = yup.object().shape({
  id: yup
    .string()
    .required("ID를 입력해주세요.")
    .min(3, "ID는 3글자 이상이어야 합니다.")
    .matches(
      /^[가-힣a-zA-Z][^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]*$/,
      "ID를 확인해주세요."
    ),
  password: yup
    .string()
    .required("비밀번호를 입력해주세요.")
    .min(4, "비밀번호는 4글자 이상이어야 합니다."),
});

const defaultValues = {
  password: "",
  id: "",
};

function LoginPage() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  // Redirect if logged in
  useEffect(() => {
    if (user) {
      navigate(isMobile ? "/qr" : "/sfv");
    }
  }, [user, navigate, isMobile]);

  const settings = {
    skin: "default",
    mode: "light",
    themeColor: "primary",
  };

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const auth = useAuth();
  const theme = useTheme();

  const handleClickShowPassword = () => setIsPasswordShown((show) => !show);

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { id, password } = data;
    auth.login({ id, password }, () => {
      setError("id", {
        type: "manual",
        message: "id 혹은 비밀번호를 확인해주세요.",
      });
    });
  };

  return (
    <LoginWrapper>
      <Card>
        <CardContent
          sx={{ p: (theme) => `${theme.spacing(10.5, 8, 8)} !important` }}
        >
          <Box
            sx={{
              mb: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h2" sx={{ ml: 2.5, fontWeight: 700 }}>
              인사이트윈
            </Typography>
          </Box>
          <Box sx={{ mb: 8 }}>
            <Typography variant="h3" sx={{ mb: 2.5 }}>
              디지털트윈 플랫폼
            </Typography>
          </Box>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="id"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <CustomTextField
                  autoFocus
                  fullWidth
                  label="아이디"
                  sx={{ mb: 6 }}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder="ID를 입력하세요."
                  error={Boolean(errors.id)}
                  {...(errors.id && { helperText: errors.id.message })}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <CustomTextField
                  fullWidth
                  value={value}
                  onBlur={onBlur}
                  sx={{
                    mb: 1.5,
                    '& input[type="password"]::-ms-reveal': {
                      display: "none",
                    },
                    '& input[type="password"]::-ms-clear': {
                      display: "none",
                    },
                  }}
                  label="패스워드"
                  onChange={onChange}
                  placeholder="············"
                  id="outlined-adornment-password"
                  error={Boolean(errors.password)}
                  {...(errors.password && {
                    helperText: errors.password.message,
                  })}
                  type={isPasswordShown ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowPassword}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {isPasswordShown ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mb: 4, mt: 6, fontSize: "1.4rem" }}
            >
              로그인
            </Button>
          </form>
        </CardContent>
      </Card>
    </LoginWrapper>
  );
}

export default LoginPage;
