export const mapping = {
  asset_code: "설비코드",
  asset_name: "설비명",
  asset_type: "설비분류",
  asset_group: "설비 그룹",
  asset_grade: "설비 등급",
  asset_manufacturer: "제조사",
  asset_model: "모델명",
  asset_spec: "설비 규격",
  asset_purchase: "설비 구입일",
  asset_manufacture: "설비 제조일",
  asset_age: "내용년수",
  asset_size: "설비 사이즈",
  asset_voltage: "전압",
  asset_wattage: "전력",
  asset_status: "설비 상태",
  asset_description: "설비 상태에 따른 설명",
  inspect_list: "점검 항목",
  inspect_timing: "점검 시기",
  inspect_standard: "점검 기준",
  inspect_method: "점검 방법",
  risk_danger_level: "위험성 수준",
  risk_factor_code: "유해위험코드",
  risk_measure: "개선대책",
  risk_measure_expect: "개선 예정일",
  risk_measure_end: "개선 완료일",
  risk_cause: "유해위험요인",
  risk_type: "유해위험분류",
  risk_description: "유해위험요인 발생 상황 및 결과",
  danger1: "하",
  danger2: "중",
  dnager3: "상",
  dangerEntire: "위험성평가 총건수",
  total_expense: "보전비용",
  total_result_man: "보전수행공수",
  total_active: "보전수행건수",
  oldRate: "설비노후화율",
  averageUsedAge: "평균 사용설비연수",
  titleBreakdown: "고장도수율",
  titleFrequency: "고장강도율",
  trustRate: "설비신뢰도",
  titleDaily: "일상점검율",
  titleDangerNg: "일상점검 불량율",
  titlePeriodic: "정기점검율",
  titlePeriodicNg: "정기점검 불량율",
};

export const numberMapping = [
  "asset_usable",
  "asset_used",
  "asset_age",
  "asset_status",
  "productivity_point",
  "quality_point",
  "maintenance_point",
  "safety_point",
  "etc_point",
  "total_point",
  "gender",
  "employee_status",
  "ma_planset_man",
  "ma_result_man",
  "risk_danger_level",
];
