import styled from "styled-components";

export const Container = styled.div`
  margin: 30px 0px 0px 100px;
  width: 90%;
`;

export const Box = styled.div`
  background-color: #0070f3;
  color: white;
  width: 500px;
  margin: 30px 0px 30px 0px;
  padding: 20px 0px 10px 20px;
  border-radius: 10px;
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 300;
`;

export const ThElement = styled.th`
  font-weight: normal;
`;

// export const Box = styled.div`
//   width: 500px;
//   background: blue;
//   margin: 30px 0px 30px 50px;
//   border-radius: 8px;
// `;

// export const Title = styled.div`
//   width: 300px;
//   font-weight: bold;
//   color: white;
//   margin-left: 30px;
//   font-size: 1.3rem;
// `;
