export const calculateYearsFromDate = (dateString) => {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  const yearDiff = currentDate.getFullYear() - targetDate.getFullYear();
  if (
    currentDate.getMonth() < targetDate.getMonth() ||
    (currentDate.getMonth() === targetDate.getMonth() &&
      currentDate.getDate() < targetDate.getDate())
  ) {
    return yearDiff - 1;
  } else {
    return yearDiff;
  }
};
