import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoute({ children }) {
  const user = useSelector(state => state.auth.user || null);
  if (!user) return <Navigate to="/" replace />;
  return children;
}

export default ProtectedRoute;