import { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";

export const useMaintenanceData = () => {
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [planSetData, setPlanSetData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(API_URL + "/data/getMaintenancePlan");
        const setD = await axios.get(API_URL + "/data/getMaintenancePlanSet");
        setPlanData(res.data);
        setPlanSetData(setD.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return {
    loading,
    setPlanData,
    setPlanSetData,
    planData,
    planSetData,
  };
};
