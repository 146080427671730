import React from "react";
import * as s from "../styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ContentTop3({
  initial,
  $valueSize,
  $4KvalueSize,
  $labelSize,
  $4KlabelSize,
}) {
  return (
    <>
      <s.Container>
        {initial.map((item, idx) => (
          <s.DataContainer key={item.id}>
            <s.Label $4KlabelSize={$4KlabelSize} $labelSize={$labelSize}>
              {item.label}
            </s.Label>
            <s.Top3Box>
              {item.data.map((item, i) => {
                const [name, value] = item.split(" - ");
                return (
                  <s.Top3Value
                    $valueSize={$valueSize}
                    $4KvalueSize={$4KvalueSize}
                    key={i}
                  >
                    <span>{name}</span>
                    <span>{value}%</span>
                  </s.Top3Value>
                );
              })}
            </s.Top3Box>
          </s.DataContainer>
        ))}
      </s.Container>
      <s.IconBox>
        <ExpandMoreIcon style={{ fontSize: "1.7rem", color: "white" }} />
      </s.IconBox>
    </>
  );
}

export default ContentTop3;
