import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";

export const useEntireAsset = () => {
  const [assetCodeSet, setAssetcodeSet] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(API_URL + "/data/getMasterAsset");
        const setted = [...new Set(res.data.map((item) => item.asset_code))];
        setAssetcodeSet(setted);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);
  return {
    assetCodeSet,
  };
};
