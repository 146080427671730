import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ProgressInfo({ startTime, endTime }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2" color="rgb(255, 255, 255)">작업시작일시</Typography>
        <Typography variant="body2" color="#878a99">{startTime}</Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2" color="rgb(255, 255, 255)">예상완료일시</Typography>
        <Typography variant="body2" color='#878a99'>{endTime}</Typography>
      </Box>
    </Box>
  );
}