import API_URL from "./API_URL";
import axios from "axios";
import { toast } from "react-toastify";

export const postData = async (
  val,
  param,
  closeModal,
  updateStatus,
  setUpdateStatus
) => {
  try {
    const res = await axios.post(API_URL + `/data/${param}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: val,
    });
    if (res.status === 400) {
      toast.error("PK 중복입니다.");
    }
    if (res.status === 200) {
      toast.success("추가가 완료되었습니다.");
      setUpdateStatus(!updateStatus);
      closeModal();
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteData = async (
  data,
  param,
  updateStatus,
  setUpdateStatus
) => {
  try {
    if (window.confirm("삭제하시겠습니까? 해당 작업은 취소할 수 없습니다.")) {
      const queryString = data.join(",");
      const res = await axios.delete(
        API_URL + `/data/${param}?data=${queryString}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        toast.success("삭제가 완료되었습니다.");
        setUpdateStatus(!updateStatus);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const putData = async (
  data,
  param,
  closeModal,
  updateStatus,
  setUpdateStatus
) => {
  try {
    const res = await axios.put(API_URL + `/data/${param}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
    if (res.status === 200) {
      toast.success("수정이 완료되었습니다.");
      setUpdateStatus(!updateStatus);
      closeModal();
    }
  } catch (e) {
    console.log(e);
  }
};
