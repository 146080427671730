import styled from "styled-components";

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 83vh;
`;

export const TopLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 10px;
`;

export const BottomWrapper = styled.div`
  margin-top: auto;
`;
