import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import {
  createBarChartOptions,
  createHorizontalBarChartOptions,
} from "../utils/chartConfig";

const processData = (data) => {
  const monthSet = [...new Set(data.map((item) => item.insp_month))];
  const aggregateData = (key) =>
    monthSet.map((month) =>
      data
        .filter((item) => item.insp_month === month)
        .reduce((sum, item) => sum + item[key], 0)
    );
  const dailyPlanSeriesData = aggregateData("insp_daily_plan");
  const dailyPerformanceSeriesData = aggregateData("insp_daily_performance");
  const periodicPlanSeriesData = aggregateData("insp_periodic_plan");
  const periodicPerformanceSeriesData = aggregateData(
    "insp_periodic_performance"
  );
  const calculateRate = (performance, plan) =>
    performance.map((perf, i) => ((perf / plan[i]) * 100).toFixed(1));
  const dailyRate = calculateRate(
    dailyPerformanceSeriesData,
    dailyPlanSeriesData
  );
  const periodicRate = calculateRate(
    periodicPerformanceSeriesData,
    periodicPlanSeriesData
  );
  const assetNameSet = [...new Set(data.map((item) => item.asset_name))].filter(
    (item) => !["사출", "밴", "프"].some((prefix) => item.startsWith(prefix))
  );
  const calculateAssetRate = (performanceKey, planKey) =>
    assetNameSet.map((asset) => {
      const assetData = data.filter((item) => item.asset_name === asset);
      const performance = assetData.reduce(
        (sum, item) => sum + item[performanceKey],
        0
      );
      const plan = assetData.reduce((sum, item) => sum + item[planKey], 0);
      return ((performance / plan) * 100).toFixed(1);
    });
  const dailyRateForGraph3 = calculateAssetRate(
    "insp_daily_performance",
    "insp_daily_plan"
  );
  const periodicRateForGraph3 = calculateAssetRate(
    "insp_periodic_performance",
    "insp_periodic_plan"
  );
  const dailyRateForGraph3Ng = calculateAssetRate(
    "insp_daily_ng",
    "insp_daily_performance"
  );
  const periodicRateForGraph3Ng = calculateAssetRate(
    "insp_periodic_ng",
    "insp_periodic_performance"
  );
  return {
    monthSet,
    dailyPlanSeriesData,
    dailyPerformanceSeriesData,
    periodicPlanSeriesData,
    periodicPerformanceSeriesData,
    dailyRate,
    periodicRate,
    assetNameSet,
    dailyRateForGraph3,
    periodicRateForGraph3,
    dailyRateForGraph3Ng,
    periodicRateForGraph3Ng,
  };
};

export const useDataInspection = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const [inspectionOptions, setInspectionOptions] = useState({});
  const [inspectionBig, setInspectionBig] = useState({});
  const [inspectionTitle, setInspectionTitle] = useState([]);
  const handleOverlay = useCallback(
    (param) => {
      dispatch(setPopStatus(!popStatus));
      dispatch(setPopShowing(param));
    },
    [dispatch, popStatus]
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(false);
        const res = await axios.get(API_URL + "/data/getDataInspection");
        const processedData = processData(res.data);

        const dailyInspectionSeries = [
          {
            name: "일상점검 계획",
            type: "bar",
            data: processedData.dailyPlanSeriesData,
            color: "#6A60A9",
          },
          {
            name: "일상점검 실적",
            type: "bar",
            data: processedData.dailyPerformanceSeriesData,
            color: "#DEDCEE",
            yAxisIndex: 1,
          },
          {
            name: "일상점검율",
            type: "line",
            data: processedData.dailyRate,
            lineStyle: { color: "#FC913A" },
            itemStyle: { color: "#FC913A" },
            yAxisIndex: 2,
          },
        ];

        const periodicInspectionSeries = [
          {
            name: "정기점검 계획",
            type: "bar",
            data: processedData.periodicPlanSeriesData,
            color: "#6A60A9",
          },
          {
            name: "정기점검 실적",
            type: "bar",
            data: processedData.periodicPerformanceSeriesData,
            color: "#DEDCEE",
            yAxisIndex: 1,
          },
          {
            name: "정기점검율",
            type: "line",
            data: processedData.periodicRate,
            lineStyle: { color: "#FC913A" },
            itemStyle: { color: "#FC913A" },
            yAxisIndex: 2,
          },
        ];

        const yAxisConfig = [
          { type: "value", show: false, min: 2000, max: 2800, interval: 100 },
          { type: "value", show: false, min: 2000, max: 2800, interval: 100 },
          { type: "value", show: false, min: 95, max: 100, interval: 1 },
        ];

        const yAxisConfig2 = [
          { type: "value", show: false, min: 1900, max: 2200, interval: 100 },
          { type: "value", show: false, min: 1900, max: 2200, interval: 100 },
          { type: "value", show: false, min: 97, max: 100, interval: 1 },
        ];
        const inspectionOption1 = createBarChartOptions(
          "일상점검 준수율(건)",
          processedData.monthSet,
          dailyInspectionSeries,
          yAxisConfig,
          () => handleOverlay("inspection1"),
          false,
          false
        );
        const inspectionBig1 = createBarChartOptions(
          "일상점검 준수율(건)",
          processedData.monthSet,
          dailyInspectionSeries,
          yAxisConfig,
          () => handleOverlay("inspection1"),
          true,
          false
        );
        const inspectionOption2 = createBarChartOptions(
          "정기점검 준수율(건)",
          processedData.monthSet,
          periodicInspectionSeries,
          yAxisConfig2,
          () => handleOverlay("inspection2"),
          false,
          false
        );
        const inspectionBig2 = createBarChartOptions(
          "정기점검 준수율(건)",
          processedData.monthSet,
          periodicInspectionSeries,
          yAxisConfig2,
          () => handleOverlay("inspection2"),
          true,
          false
        );
        const complianceRateSeries = [
          {
            name: "정기점검율",
            type: "bar",
            color: "#6A60A9",
            data: processedData.periodicRateForGraph3,
          },
          {
            name: "일상점검율",
            type: "bar",
            color: "#DEDCEE",
            data: processedData.dailyRateForGraph3,
          },
        ];

        const defectRateSeries = [
          {
            name: "정기점검 불량율",
            type: "bar",
            color: "#FE4365",
            data: processedData.periodicRateForGraph3Ng,
          },
          {
            name: "일상점검 불량율",
            type: "bar",
            color: "#FEC8C9",
            data: processedData.dailyRateForGraph3Ng,
          },
        ];

        const xAxisConfig = [
          { type: "value", show: false, min: 95, max: 101, interval: 1 },
        ];
        const inspectionOption3 = createHorizontalBarChartOptions(
          "설비별 점검 준수율(%)",
          processedData.assetNameSet,
          complianceRateSeries,
          xAxisConfig,
          () => handleOverlay("inspection3"),
          false,
          true
        );

        const inspectionBig3 = createHorizontalBarChartOptions(
          "설비별 점검 준수율(%)",
          processedData.assetNameSet,
          complianceRateSeries,
          xAxisConfig,
          () => handleOverlay("inspection3"),
          true,
          true
        );
        const inspectionOption4 = createHorizontalBarChartOptions(
          "설비별 점검 불량율(%)",
          processedData.assetNameSet,
          defectRateSeries,
          [{ type: "value", show: false }],
          () => handleOverlay("inspection4"),
          false,
          true
        );
        const inspectionBig4 = createHorizontalBarChartOptions(
          "설비별 점검 불량율(%)",
          processedData.assetNameSet,
          defectRateSeries,
          [{ type: "value", show: false }],
          () => handleOverlay("inspection4"),
          true,
          true
        );
        setInspectionOptions({
          inspectionOption1,
          inspectionOption2,
          inspectionOption3,
          inspectionOption4,
        });
        setInspectionBig({
          inspectionBig1,
          inspectionBig2,
          inspectionBig3,
          inspectionBig4,
        });
        const calculateAverage = (data) =>
          (
            data.reduce((sum, val) => sum + Number(val), 0) / data.length
          ).toFixed(2);
        setInspectionTitle([
          {
            id: 1,
            label: "일상점검율",
            value: `${calculateAverage(processedData.dailyRate)}%`,
            color: "white",
          },
          {
            id: 2,
            label: "일상점검 불량율",
            value: `${calculateAverage(processedData.dailyRateForGraph3Ng)}%`,
            color: "#e599f7",
          },
          {
            id: 3,
            label: "정기점검율",
            value: `${calculateAverage(processedData.periodicRate)}%`,
            color: "white",
          },
          {
            id: 4,
            label: "정기점검 불량율",
            value: `${calculateAverage(
              processedData.periodicRateForGraph3Ng
            )}%`,
            color: "#e599f7",
          },
        ]);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, [handleOverlay]);

  return {
    inspectionTitle,
    inspectionOptions,
    inspectionBig,
  };
};
