import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "../components/Login/LoginPage";
import SfvPage from "../components/SfvPage/SfvPage";
import MasterAsset from "../components/M/M_Asset";
import ThemeComponent from "../themes/ThemeComponent";

function AllRoute() {
  const settings = {
    mode: "dark",
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ThemeComponent settings={settings}>
            <LoginPage />
          </ThemeComponent>
        }
      />
      <Route
        path="/sfv"
        element={
          <ThemeComponent settings={settings}>
            <ProtectedRoute children={<SfvPage />} />
          </ThemeComponent>
        }
      />
      <Route path="/asset" element={<MasterAsset />} />
    </Routes>
  );
}

export default AllRoute;
