import { useEffect } from "react";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoute from "./Route/AllRoute";
import "./App.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchStorage } from "./slice/authSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    injectStyle();
  }, []);
  useEffect(() => {
    dispatch(fetchStorage());
  }, []);

  const assetCode = useSelector((state) => state.auth.assetCode);
  return (
    <Router>
      <AuthProvider>
          <AllRoute />
          <ToastContainer
            autoClose={1000}
            hideProgressBar
            pauseOnHover={false}
            closeOnClick
            theme="dark"
            limit={1}
            queueMax={0}
          />
      </AuthProvider>
    </Router>
  );
}

export default App;
