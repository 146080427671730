import { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import {
  calculateBusinessHours,
  getBusinessHoursInMonth,
} from "../utils/getWorkHour";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import {
  createBarChartOptions,
  createHorizontalBarChartOptions,
  createScatterChartOptions,
} from "../utils/chartConfig";

const calculateHours = (occur, end) => {
  const occurDate = new Date(occur);
  const endDate = new Date(end);
  const diffInMs = endDate - occurDate;
  const diffInHours = diffInMs / (1000 * 60 * 60);
  return diffInHours;
};

const mergeAndSortArrays = (arrA, arrB) => {
  const mergedArray = arrA.map((value, index) => `${arrB[index]} - ${value}`);
  const sortedArray = mergedArray.sort((a, b) => {
    const numA = parseFloat(a.split(" - ")[1]);
    const numB = parseFloat(b.split(" - ")[1]);
    return numB - numA;
  });
  return sortedArray;
};

export const useBrokenData = () => {
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };
  const [loading, setLoading] = useState(false);
  const [brokenOptions, setBrokenOptions] = useState({});
  const [brokenBig, setBrokenBig] = useState({});
  const [brokenTitleConfig, setBrokenTitleConfig] = useState([]);
  const [brokenTitleConfig2, setBrokenTitleConfig2] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(API_URL + "/data/getBrokenData");
        const sortedRes = res.data.sort((a, b) => {
          if (a.ma_breakdown_occur_short < b.ma_breakdown_occur_short)
            return -1;
          if (a.ma_breakdown_occur_short > b.ma_breakdown_occur_short) return 1;
          return 0;
        });
        const fiveDayBusinessHour = calculateBusinessHours(5);
        const assetNameSet = [
          ...new Set(sortedRes.map((item) => item.asset_name)),
        ].sort();

        // 월별 고장율 추이 그래프
        const monthSet = [
          ...new Set(sortedRes.map((item) => item.ma_breakdown_occur_short)),
        ].filter((item) => item !== null);
        const frequencyNgSeries = [];
        const breakdownStrengthSeries = [];
        for (let i = 0; i < monthSet.length; i++) {
          const target = sortedRes.filter(
            (item) => item.ma_breakdown_occur_short === monthSet[i]
          );
          const sumOfStop = target
            .map((item) =>
              calculateHours(item.ma_breakdown_occur, item.ma_breakdown_end)
            )
            .reduce((acc, cur) => acc + cur);
          const sumOfLoad = getBusinessHoursInMonth(i + 1) * target.length;
          const sumOfProcessT = sumOfLoad - sumOfStop;
          frequencyNgSeries.push(
            ((target.length / sumOfProcessT) * 100).toFixed(1)
          );
          breakdownStrengthSeries.push(
            ((sumOfStop / sumOfProcessT) * 100).toFixed(1)
          );
        }

        const brokenOptions4 = createBarChartOptions(
          "월별 고장율 추이(%)",
          monthSet,
          [
            {
              data: frequencyNgSeries,
              type: "line",
              // color: "#7367F0",
              color: "#FE4365",
              name: "고장도수율(월별)",
              label: {
                show: true,
                position: "inside",
                color: "black",
                backgroundColor: "white",
                borderColor: "#FE4365",
                borderWidth: 1,
                borderRadius: 35,
                padding: [2, 2],
                fontSize: 10,
              },
            },
            {
              data: breakdownStrengthSeries,
              type: "line",
              // color: "#7367F0",
              color: "#FEC8C9",
              name: "고장강도율(월별)",
              label: {
                show: true,
                position: "inside",
                color: "black",
                backgroundColor: "white",
                borderColor: "#FEC8C9",
                borderWidth: 1,
                borderRadius: 35,
                padding: [2, 2],
                fontSize: 10,
              },
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("broken4"),
          false,
          true
        );

        const brokenBig4 = createBarChartOptions(
          "월별 고장율 추이(%)",
          monthSet,
          [
            {
              data: frequencyNgSeries,
              type: "line",
              // color: "#7367F0",
              color: "#FE4365",
              name: "고장도수율(월별)",
              label: {
                show: true,
                position: "inside",
                color: "black",
                backgroundColor: "white",
                borderColor: "#FE4365",
                borderWidth: 1,
                borderRadius: 35,
                padding: [2, 2],
                fontSize: 10,
              },
            },
            {
              data: breakdownStrengthSeries,
              type: "line",
              // color: "#7367F0",
              color: "#FEC8C9",
              name: "고장강도율(월별)",
              label: {
                show: true,
                position: "inside",
                color: "black",
                backgroundColor: "white",
                borderColor: "#FEC8C9",
                borderWidth: 1,
                borderRadius: 35,
                padding: [2, 2],
                fontSize: 10,
              },
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("broken4"),
          true,
          true
        );

        // 고장도수율, 고장강도율 계산
        const frequencyNgSeries2 = [];
        const frequencyNgXAxis = [];
        const breakdownStrengthSeries2 = [];
        const breakdownStrengthXAxis = [];
        const groupSet = [
          ...new Set(sortedRes.map((item) => item.asset_group)),
        ];

        const stopTime = sortedRes
          .map((item) =>
            calculateHours(item.ma_breakdown_occur, item.ma_breakdown_end)
          )
          .reduce((acc, cur) => acc + cur);
        const trustRate = (
          ((calculateBusinessHours(5) * assetNameSet.length - stopTime) /
            (calculateBusinessHours(5) * assetNameSet.length)) *
          100
        ).toFixed(1);

        for (let i = 0; i < groupSet.length; i++) {
          const target = sortedRes.filter(
            (item) => item.asset_group === groupSet[i]
          );
          const tragetGradeSet = [
            ...new Set(target.map((item) => item.asset_grade)),
          ].sort();
          for (let ii = 0; ii < tragetGradeSet.length; ii++) {
            const realTarget = sortedRes.filter(
              (item) =>
                item.asset_grade === tragetGradeSet[ii] &&
                item.asset_group === groupSet[i]
            );
            const sumOfStop = realTarget
              .map((item) =>
                calculateHours(item.ma_breakdown_occur, item.ma_breakdown_end)
              )
              .reduce((acc, cur) => acc + cur);
            const sumOfLoad = calculateBusinessHours(5) * realTarget.length;
            const sumOfProcessT = sumOfLoad - sumOfStop;
            frequencyNgSeries2.push(
              ((realTarget.length / sumOfProcessT) * 100).toFixed(2)
            );
            frequencyNgXAxis.push(`${tragetGradeSet[ii]} \n ${groupSet[i]}`);
            breakdownStrengthSeries2.push(
              ((sumOfStop / sumOfProcessT) * 100).toFixed(2)
            );
            breakdownStrengthXAxis.push(
              `${tragetGradeSet[ii]} \n ${groupSet[i]}`
            );
          }
        }
        const titleFrequency = `고장도수율 (${(
          frequencyNgSeries2.reduce((a, b) => Number(a) + Number(b), 0) /
          frequencyNgSeries2.length
        ).toFixed(2)}%)`;
        const titleStrength = `고장강도율 (${(
          breakdownStrengthSeries2.reduce((a, b) => Number(a) + Number(b), 0) /
          breakdownStrengthSeries2.length
        ).toFixed(2)}%)`;

        const brokenOptions1 = createBarChartOptions(
          titleFrequency,
          frequencyNgXAxis,
          [
            {
              data: frequencyNgSeries2,
              type: "bar",
              color: "#FE4365",
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("broken1")
        );

        const brokenBig1 = createBarChartOptions(
          titleFrequency,
          frequencyNgXAxis,
          [
            {
              data: frequencyNgSeries2,
              type: "bar",
              color: "#FE4365",
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("broken1"),
          true
        );

        const brokenOptions2 = createBarChartOptions(
          titleStrength,
          breakdownStrengthXAxis,
          [
            {
              data: breakdownStrengthSeries2,
              type: "bar",
              color: "#FEC8C9",
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("broken2")
        );

        const brokenBig2 = createBarChartOptions(
          titleStrength,
          breakdownStrengthXAxis,
          [
            {
              data: breakdownStrengthSeries2,
              type: "bar",
              color: "#FEC8C9",
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("broken2"),
          true
        );

        // 설비별 고장율
        const assetBrokenSet = [];
        for (let i = 0; i < assetNameSet.length; i++) {
          const target = sortedRes.filter(
            (item) => item.asset_name === assetNameSet[i]
          );
          const sumOfStop = target
            .map((item) =>
              calculateHours(item.ma_breakdown_occur, item.ma_breakdown_end)
            )
            .reduce((acc, cur) => acc + cur);
          const sumOfLoad = calculateBusinessHours(5);
          const sumOfProcessT = sumOfLoad - sumOfStop;
          assetBrokenSet.push({
            name: assetNameSet[i],
            value: ((sumOfStop / sumOfProcessT) * 100).toFixed(2),
          });
        }
        const sortedAssetBroken = assetBrokenSet.sort(
          (a, b) => b.value - a.value
        );

        // TOP3 고장원인
        const frequencyNgMachine = [];
        const breakdownStrengthMachine = [];
        const scatterData = [];
        const scatterSeries = [];
        const fullArray = [];

        for (let i = 0; i < assetNameSet.length; i++) {
          const target = sortedRes.filter(
            (item) => item.asset_name === assetNameSet[i]
          );
          const sumOfStop = target
            .map((item) =>
              calculateHours(item.ma_breakdown_occur, item.ma_breakdown_end)
            )
            .reduce((acc, cur) => acc + cur);
          //   const sumOfLoad = getBusinessHoursInMonth(i + 1) * target.length;
          const sumOfLoad = calculateBusinessHours(5);
          const sumOfProcessT = sumOfLoad - sumOfStop;
          frequencyNgMachine.push(
            !sumOfStop ? 0 : ((target.length / sumOfProcessT) * 100).toFixed(1)
          );
          breakdownStrengthMachine.push(
            !sumOfStop ? 0 : ((sumOfStop / sumOfProcessT) * 100).toFixed(1)
          );
          fullArray.push({
            name: assetNameSet[i],
            frequency: String(
              !sumOfStop
                ? 0
                : ((target.length / sumOfProcessT) * 100).toFixed(1)
            ),
            breakdown: String(
              !sumOfStop ? 0 : ((sumOfStop / sumOfProcessT) * 100).toFixed(1)
            ),
          });
          scatterData.push([
            ((target.length / sumOfProcessT) * 100).toFixed(1),
            ((sumOfStop / sumOfProcessT) * 100).toFixed(1),
          ]);
          scatterSeries.push({
            name: assetNameSet[i],
            type: "scatter",
            data: [
              [
                ((target.length / sumOfProcessT) * 100).toFixed(1),
                ((sumOfStop / sumOfProcessT) * 100).toFixed(1),
              ],
            ],
          });
        }
        const frequencyTop3 = mergeAndSortArrays(
          frequencyNgMachine,
          assetNameSet
        ).filter((item, idx) => idx <= 2);
        const breakdownTop3 = mergeAndSortArrays(
          breakdownStrengthMachine,
          assetNameSet
        ).filter((item, idx) => idx <= 2);
        const breakdownSortedArray = [...fullArray].sort(
          (a, b) => parseFloat(a.breakdown) - parseFloat(b.breakdown)
        );
        const causeSet = [
          ...new Set(sortedRes.map((item) => item.cause_class)),
        ];
        const top3CauseSet = [];
        for (let i = 0; i < causeSet.length; i++) {
          const target = sortedRes.filter(
            (item) => item.cause_class === causeSet[i]
          );
          top3CauseSet.push({
            name: causeSet[i],
            count: target.length,
            time: target
              .map((item) =>
                calculateHours(item.ma_breakdown_occur, item.ma_breakdown_end)
              )
              .reduce((acc, cur) => acc + cur),
          });
        }
        const sortedTop3CauseSet = top3CauseSet.sort(
          (a, b) => b.count - a.count
        );
        const top3Cause = sortedTop3CauseSet.slice(0, 3);
        const frequencySortedArray = [...fullArray].sort(
          (a, b) => parseFloat(a.frequency) - parseFloat(b.frequency)
        );

        const top3Options1 = createHorizontalBarChartOptions(
          "설비별 고장율(%)",
          frequencySortedArray.map((item) => item.name),
          [
            {
              type: "bar",
              name: "고장도수율",
              data: frequencySortedArray.map((item) => item.frequency),
              color: "#FE4365",
              fontSize: 10,
            },
            {
              type: "bar",
              name: "고장강도율",
              data: frequencySortedArray.map((item) => item.breakdown),
              color: "#FEC8C9",
              fontSize: 10,
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("top31"),
          false,
          true
        );

        const topBig1 = createHorizontalBarChartOptions(
          "설비별 고장율(%)",
          frequencySortedArray.map((item) => item.name),
          [
            {
              type: "bar",
              name: "고장도수율",
              data: frequencySortedArray.map((item) => item.frequency),
              color: "#FE4365",
              fontSize: 10,
            },
            {
              type: "bar",
              name: "고장강도율",
              data: frequencySortedArray.map((item) => item.breakdown),
              color: "#FEC8C9",
              fontSize: 10,
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("top31"),
          true,
          true
        );

        const top3Options2 = createHorizontalBarChartOptions(
          "설비별 고장도수율(%)",
          frequencySortedArray.map((item) => item.name),
          [
            {
              type: "bar",
              name: "고장도수율",
              data: frequencySortedArray.map((item) => item.frequency),
              color: "#FE4365",
              fontSize: 10,
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("top32"),
          false,
          false
        );

        const topBig2 = createHorizontalBarChartOptions(
          "설비별 고장도수율(%)",
          frequencySortedArray.map((item) => item.name),
          [
            {
              type: "bar",
              name: "고장도수율",
              data: frequencySortedArray.map((item) => item.frequency),
              color: "#FE4365",
              fontSize: 10,
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("top32"),
          true,
          false
        );

        const top3Options3 = createHorizontalBarChartOptions(
          "설비별 고장강도율(%)",
          breakdownSortedArray.map((item) => item.name),
          [
            {
              type: "bar",
              name: "고장강도율",
              data: breakdownSortedArray.map((item) => item.breakdown),
              color: "#FEC8C9",
              fontSize: 10,
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("top33"),
          false,
          false
        );
        const topBig3 = createHorizontalBarChartOptions(
          "설비별 고장강도율(%)",
          breakdownSortedArray.map((item) => item.name),
          [
            {
              type: "bar",
              name: "고장강도율",
              data: breakdownSortedArray.map((item) => item.breakdown),
              color: "#FEC8C9",
              fontSize: 10,
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("top33"),
          true,
          false
        );
        // matrix
        const yMin = Math.min(...scatterData.map((item) => item[1]));
        const yMax = Math.max(...scatterData.map((item) => item[1]));

        const yMid = (yMin + yMax) / 2;
        const xMin = 0;
        const xMax = Math.max(...scatterData.map((item) => item[0]));
        const xMid = (xMin + xMax) / 2;

        const scatterSeries2 = scatterSeries.map((item) => {
          return {
            ...item,
            markLine: {
              lineStyle: {
                type: "solid",
                color: "white",
              },
              data: [
                {
                  name: "고장강도율",
                  yAxis: yMid,
                },
                {
                  name: "고장도수율",
                  xAxis: xMid,
                },
              ],
            },
          };
        });

        const brokenMatrix = createScatterChartOptions(
          "고장율 매트릭스",
          scatterSeries2,
          () => handleOverlay("brokenMatrix")
        );

        const brokenBigMatrix = createScatterChartOptions(
          "고장율 매트릭스",
          scatterSeries2,
          () => handleOverlay("brokenMatrix"),
          true
        );

        setBrokenOptions({
          brokenOptions1,
          brokenOptions2,
          brokenOptions4,
          brokenMatrix,
          top3Options1,
          top3Options2,
          top3Options3,
        });

        setBrokenBig({
          brokenBig1,
          brokenBig2,
          brokenBig4,
          brokenBigMatrix,
          topBig1,
          topBig2,
          topBig3,
        });

        setBrokenTitleConfig([
          {
            id: 1,
            label: "고장도수율",
            value: `${(
              frequencyNgSeries2.reduce((a, b) => Number(a) + Number(b), 0) /
              frequencyNgSeries2.length
            ).toFixed(2)}%`,
            color: "#e599f7",
          },
          {
            id: 2,
            label: "고장강도율",
            value: `${(
              breakdownStrengthSeries2.reduce(
                (a, b) => Number(a) + Number(b),
                0
              ) / breakdownStrengthSeries2.length
            ).toFixed(2)}%`,
            color: "#e599f7",
          },
          {
            id: 3,
            label: "설비신뢰도",
            value: `${trustRate}%`,
            color: "white",
          },
        ]);
        setBrokenTitleConfig2([
          {
            id: 1,
            label: "고장도수율 TOP3",
            data: frequencyTop3,
          },
          {
            id: 2,
            label: "고장강도율 TOP3",
            data: breakdownTop3,
          },
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching broken data:", error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return {
    loading,
    brokenOptions,
    brokenBig,
    brokenTitleConfig,
    brokenTitleConfig2,
  };
};
