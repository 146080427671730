import axios from "axios";
import API_URL from "../utils/API_URL";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import {
  createBarChartOptions,
  createHorizontalBarChartOptions,
  createTreemapChartOptions,
} from "../utils/chartConfig";

const negativeColorSet = ["#FE4365", "#FEC8C9", "#D86ECC"];

export const useFailureData = () => {
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const [failData, setFailData] = useState({});
  const [failBig, setFailBig] = useState({});
  const [failTitle, setFailTitle] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };

  const createChartOptions = (res, res2, res3, isLarge = false) => {
    const monthSet = [...new Set(res.data.map((item) => item.month))];
    const nameArr = ["defect_in", "defect_proc", "defect_final"];
    const nameArr2 = ["입고검사 불량율", "공정검사 불량율", "최종검사 불량율"];

    const opt1Series = nameArr.map((name, i) => ({
      name: nameArr2[i],
      type: "bar",
      color: negativeColorSet[i],
      data: res.data.map((item) => (item[name] * 100).toFixed(2)),
    }));

    const res3AllSum = res3.data
      .map((item) => item.defect_count)
      .reduce((acc, cur) => acc + cur);

    const opt4Arr = res3.data.map((item) => ({
      name: item.defect_type,
      value: ((item.defect_count / res3AllSum) * 100).toFixed(1),
    }));

    const opt3Arr = res2.data
      .map((item) => ({
        asset_name: item.asset_name,
        defect_rate: (item.defect_rate * 100).toFixed(2),
      }))
      .sort((a, b) => parseFloat(a.defect_rate) - parseFloat(b.defect_rate));

    const opt1 = createBarChartOptions(
      "월별 불량율 현황(%)",
      monthSet,
      opt1Series,
      { type: "value", show: false },
      () => handleOverlay("fail1"),
      isLarge,
      true
    );

    const opt2 = createBarChartOptions(
      "월별 Claim 건수",
      monthSet,
      [
        {
          type: "bar",
          color: negativeColorSet[1],
          data: res.data.map((item) => item.claim),
        },
      ],
      { type: "value", show: false },
      () => handleOverlay("fail2"),
      isLarge
    );

    const opt3 = createHorizontalBarChartOptions(
      "설비별 불량율(%)",
      opt3Arr.map((item) => item.asset_name),
      [
        {
          type: "bar",
          name: "불량율",
          data: opt3Arr.map((item) => item.defect_rate),
          color: negativeColorSet[0],
        },
      ],
      { type: "value", show: false },
      () => handleOverlay("fail3"),
      isLarge
    );

    const opt4 = createTreemapChartOptions(
      "불량유형별 점유율(%)",
      opt4Arr,
      () => handleOverlay("fail4"),
      isLarge
    );

    return { opt1, opt2, opt3, opt4 };
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const [res, res2, res3] = await Promise.all([
        axios.get(`${API_URL}/data/getDataDefectMonthly`),
        axios.get(`${API_URL}/data/getDataMatrix`),
        axios.get(`${API_URL}/data/getDataDefectType`),
      ]);

      const chartOptions = createChartOptions(res, res2, res3);

      setFailData({
        fail1: chartOptions.opt1,
        fail2: chartOptions.opt2,
        fail3: chartOptions.opt3,
        fail4: chartOptions.opt4,
      });

      setFailBig({
        failBig1: createChartOptions(res, res2, res3, true, true).opt1,
        failBig2: createChartOptions(res, res2, res3, true).opt2,
        failBig3: createChartOptions(res, res2, res3, true, true).opt3,
        failBig4: createChartOptions(res, res2, res3, true).opt4,
      });
      const monthSet = [...new Set(res.data.map((item) => item.month))];
      const nameArr = ["defect_in", "defect_proc", "defect_final"];
      const nameArr2 = [
        "입고검사 불량율",
        "공정검사 불량율",
        "최종검사 불량율",
      ];
      const opt3Arr = res2.data
        .map((item) => ({
          asset_name: item.asset_name,
          defect_rate: (item.defect_rate * 100).toFixed(2),
        }))
        .sort((a, b) => parseFloat(a.defect_rate) - parseFloat(b.defect_rate));
      const failTitleArray = [
        {
          id: 1,
          label: "부품적합율",
          value:
            (
              (1 -
                res.data.reduce((acc, cur) => acc + cur.defect_in, 0) /
                  res.data.length) *
              100
            ).toFixed(2) + "%",
        },
        {
          id: 2,
          label: "설비불량율",
          value: `${(
            opt3Arr
              .map((item) => Number(item.defect_rate))
              .reduce((acc, cur) => acc + cur) / opt3Arr.length
          ).toFixed(2)}%`,
          color: "#ff6b6b",
        },
        {
          id: 3,
          label: "고객Claim건수",
          value: res.data
            .map((item) => item.claim)
            .reduce((acc, cur) => acc + cur),
          color: "red",
        },
      ];
      setFailTitle(failTitleArray);
    } catch (error) {
      console.error("Error fetching failure data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { failData, failBig, failTitle, loading };
};
