import { useState, useEffect, useRef } from "react";
import { cards } from "../components/Sfv_Carousel/constants";

export function useCarouselLogic(isUnityChattingOpened) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [carouselVisible, setCarouselVisible] = useState(true);
  const [animating, setAnimating] = useState(false);
  const [autoSlideTimer, setAutoSlideTimer] = useState(null);
  const cardsRef = useRef(null);
  const handleCardClick = (index) => {
    setAnimating(true);
    setSelectedCardIndex(index);
    setCarouselVisible(false);
    setTimeout(() => setAnimating(false), 500);
  };

  const handleSelectedCardClick = () => {
    const idx = selectedCardIndex.id - 1 > 4 ? 4 : selectedCardIndex.id - 1;
    setCurrentIndex(idx);
    setSelectedCardIndex(null);
    setCarouselVisible(true);
    setAnimating(true);
    setTimeout(() => setAnimating(false), 500);
  };

  const prevCard = () => {
    if (selectedCardIndex !== null) {
      setSelectedCardIndex((prev) =>
        prev.id === 1 ? cards[cards.length - 1] : cards[prev.id - 2]
      );
    } else {
      const unityAdjustNumber = isUnityChattingOpened ? 1 : 0;
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? cards.length - 3 + unityAdjustNumber : prevIndex - 1
      );
    }
    clearTimeout(autoSlideTimer);
  };

  const nextCard = () => {
    if (selectedCardIndex !== null) {
      setSelectedCardIndex((prev) =>
        prev.id === cards.length ? cards[0] : cards[prev.id]
      );
    } else {
      const unityAdjustNumber = isUnityChattingOpened ? 1 : 0;
      setCurrentIndex((prevIndex) =>
        prevIndex === cards.length - 3 + unityAdjustNumber ? 0 : prevIndex + 1
      );
    }
    clearTimeout(autoSlideTimer);
  };

  useEffect(() => {
    if (selectedCardIndex === null) {
      const timer = setTimeout(nextCard, 10000);
      setAutoSlideTimer(timer);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, selectedCardIndex]);

  useEffect(() => {
    const dividedNumber = isUnityChattingOpened ? 2 : 3;
    if (cardsRef.current) {
      cardsRef.current.style.transform = `translateX(-${
        currentIndex * (cardsRef.current.offsetWidth / dividedNumber)
      }px)`;
    }
  }, [currentIndex, carouselVisible, isUnityChattingOpened]);

  return {
    currentIndex,
    selectedCardIndex,
    carouselVisible,
    animating,
    cardsRef,
    handleCardClick,
    handleSelectedCardClick,
    prevCard,
    nextCard,
    setSelectedCardIndex,
    setCurrentIndex,
  };
}
