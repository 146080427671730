import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 3000px) {
    height: 200px;
    // max-width: 600px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0.3rem 0px 0.3rem;
  @media (min-width: 3000px) {
    padding: 20px 0.5rem 0px 0.5rem;
  }
`;
export const ProDataContainer = styled(DataContainer)``;

export const Label = styled.div`
  font-size: 0.8rem;
  height: 40px;
  color: white;
  font-weight: 500;
  font-size: ${(props) => props.$labelSize};
  @media (min-width: 3000px) {
    font-size: ${(props) => props.$4KlabelSize};
  }
`;

export const Value = styled.div`
  font-size: 1.67rem;
  font-weight: bold;
  height: 60px;
  color: ${(props) => props.color};
  font-size: ${(props) => props.$valueSize};
  @media (min-width: 3000px) {
    font-size: ${(props) => props.$4KvalueSize};
  }
`;

export const Top3DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0.3rem 0px 0.3rem;
  @media (min-width: 3000px) {
    padding: 20px 0.5rem 0px 0.5rem;
  }
`;

export const Top3Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const Top3Value = styled.div`
  font-size: 0.6rem;
  font-weight: bold;
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
  font-size: ${(props) => props.$valueSize};
  &:nth-child(1) {
    color: #ffd700;
  }
  &:nth-child(2) {
    color: #c0c0c0;
  }
  &:nth-child(3) {
    color: #cd7f32;
  }
  @media (min-width: 3000px) {
    font-size: ${(props) => props.$4KvalueSize};
  }
`;

export const ProductBox = styled.div`
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 30px;
`;
