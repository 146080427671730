import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CustomDatePicker = ({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  format = "YYYY-MM-DD",
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          width: "206px",
          "& .MuiOutlinedInput-root": {
            textAlign: "center",
          },
        }}
        label={label}
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={format}
        renderInput={(params) => <TextField {...params} />}
        format={format}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
