export const assetColumns = [
  {
    id: "select-col",
    header: ({ table }) => (
      <input
        type="checkbox"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected() || undefined}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  },
  {
    id: "asset_code",
    accessorKey: "asset_code",
    header: "설비식별코드(관리번호)",
  },
  { id: "asset_name", accessorKey: "asset_name", header: "설비명" },
  { id: "mchId", accessorKey: "mchId", header: "머신ID" },
  { id: "asset_type", accessorKey: "asset_type", header: "설비분류" },
  { id: "asset_group", accessorKey: "asset_group", header: "설비그룹" },
  { id: "asset_grade", accessorKey: "asset_grade", header: "설비등급" },
  {
    id: "asset_manufacturer",
    accessorKey: "asset_manufacturer",
    header: "제작사",
  },
  { id: "asset_model", accessorKey: "asset_model", header: "모델명" },
  { id: "asset_spec", accessorKey: "asset_spec", header: "규격" },
  { id: "asset_purchase", accessorKey: "asset_purchase", header: "구입일자" },
  {
    id: "asset_manufacture",
    accessorKey: "asset_manufacture",
    header: "제작일자",
  },
  { id: "asset_usable", accessorKey: "asset_usable", header: "내용년수" },
  { id: "asset_size", accessorKey: "asset_size", header: "설비 Size(mm)" },
  { id: "asset_voltage", accessorKey: "asset_voltage", header: "전압" },
  { id: "asset_wattage", accessorKey: "asset_wattage", header: "전력" },
  { id: "asset_manual", accessorKey: "asset_manual", header: "메뉴얼" },
  { id: "asset_status", accessorKey: "asset_status", header: "설비상태" },
  { id: "asset_description", accessorKey: "asset_description", header: "설명" },
];
