import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  padding: 10,
  backgroundColor: '#555',
  boxShadow: 'inset 0 -1px 1px rgba(255, 255, 255, 0.3)',
  '& .MuiLinearProgress-bar': {
    height: '100%',
    borderRadius: 5,
    backgroundColor: '#1b54ea',
    backgroundImage: `linear-gradient(
      -45deg, 
      rgba(255, 255, 255, .2) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(255, 255, 255, .2) 50%, 
      rgba(255, 255, 255, .2) 75%, 
      transparent 75%, 
      transparent
    )`,
    boxShadow: 'inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4)',
    animation: 'progress-bar-stripes 2s linear infinite',
    backgroundSize: `50px 50px`
  },
  '@keyframes progress-bar-stripes': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '50px 50px',
    },
  }
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <CustomLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="rgb(255, 255, 255)" sx={{ ml: '5px' }}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function calculateProgress(startTime, endTime) {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const now = new Date();
  const totalDuration = end - start;
  const elapsed = now - start;
  return Math.min((elapsed / totalDuration) * 100, 100);
}

export default function CustomProgressBar({ startTime, endTime }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (startTime && endTime) {
      setProgress(calculateProgress(startTime, endTime));
      // const timer = setInterval(() => {
      //   setProgress(calculateProgress(startTime, endTime));
      // }, 10000);
      // return () => clearInterval(timer);
    } else {
      setProgress(0);
    }
  }, [startTime, endTime]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}