import { useState, useEffect } from "react";

export const DefaultColumnCell = ({
  getValue,
  row: { index },
  column: { id },
  table,
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [initialized, setInitialized] = useState(false);
  const onBlur = () => {
    table.options.meta?.updateData(index, id, value);
    // put request code
  };
  useEffect(() => {
    setValue(initialValue);
    setInitialized(true);
  }, [initialValue]);
  return (
    <input
      className="text-center t_input"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

export const getTableColumns = (data) => {
  const columns = data.map((item) => {
    return {
      id: item,
      accessorKey: item,
      accessFn: (row) => row.item,
      header: () => item,
      cell: DefaultColumnCell,
    };
  });
  return columns;
};
