import axios from "axios";
import API_URL from "../utils/API_URL";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import {
  createBarChartOptions,
  createHorizontalBarChartProductivity,
} from "../utils/chartConfig";

const colorSet = ["#6A60A9", "#DEDCEE", "#FFC952"];

export const useProductivityData = () => {
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };
  const [loading, setLoading] = useState(false);
  const [proOptions, setProOptions] = useState({});
  const [proBig, setProBig] = useState({});
  const [productivityTitle, setProductivityTitle] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const [res, res2, utilMonthlyRes, utilRateRes, performanceRes] =
          await Promise.all([
            axios.get(API_URL + "/data/getDataProductionPerformance"),
            axios.get(API_URL + "/data/getDataInProcess"),
            axios.get(API_URL + "/data/getDataUtilMonthly"),
            axios.get(API_URL + "/data/getDataUtilRate"),
            axios.get(API_URL + "/data/getDataPlanPerformance"),
          ]);
        const sortedUtilRateRes = utilRateRes.data.sort((a, b) => {
          const nameA = a.mch_name.toUpperCase();
          const nameB = b.mch_name.toUpperCase();
          return nameA.localeCompare(nameB);
        });
        const monthSet = [
          ...new Set(res.data.map((item) => item.prod_month)),
        ];
        const groupSet = [
          ...new Set(res.data.map((item) => item.prod_group)),
        ];
        let motnSeries = groupSet.map((group, i) => {
          const target = res2.data.filter(
            (item) => item.in_process_item === group
          );
          const monthDataArr = monthSet.map((month) => {
            const realTarget = target.filter(
              (item) => item.in_process_month === month
            );
            return realTarget.reduce((acc, cur) => acc + cur.in_process_qty, 0);
          });
          return {
            name: group,
            type: "bar",
            stack: "total",
            emphasis: { focus: "series" },
            data: monthDataArr,
            color: colorSet[i % groupSet.length],
          };
        });
        const perPlanArr = performanceRes.data.map((item) => item.pp_plan);
        const perPerformanceArr = performanceRes.data.map(
          (item) => item.pp_performance
        );
        const perCompleteArr = performanceRes.data.map((item) =>
          (item.pp_complete * 100).toFixed(1)
        );
        const loadUm = utilMonthlyRes.data.map((item) =>
          (item.um_load * 100).toFixed(1)
        );
        const utilUm = utilMonthlyRes.data.map((item) =>
          (item.um_util * 100).toFixed(1)
        );
        const opt1 = (isLarge) =>
          createBarChartOptions(
            "월별 재공수량",
            monthSet,
            motnSeries,
            {
              type: "value",
              show: false,
            },
            () => handleOverlay("produictive1"),
            isLarge,
            true
          );

        const opt2 = (isLarge) =>
          createBarChartOptions(
            "월별 설비부하율 및 가동율(%)",
            monthSet,
            [
              {
                name: "설비부하율",
                type: "bar",
                data: loadUm,
                color: "#6A60A9",
              },
              {
                name: "설비가동률",
                type: "bar",
                data: utilUm,
                color: "#DEDCEE",
              },
            ],
            [
              {
                type: "value",
                show: false,
              },
            ],
            () => handleOverlay("produictive3"),
            isLarge,
            true
          );
        const opt3 = (isLarge) =>
          createBarChartOptions(
            "생산계획대비 실적",
            monthSet,
            [
              {
                name: "계획",
                type: "bar",
                data: perPlanArr,
                color: "#6A60A9",
              },
              {
                name: "실적",
                type: "bar",
                data: perPerformanceArr,
                color: "#DEDCEE",
                yAxisIndex: 1,
              },
              {
                name: "달성율",
                type: "line",
                data: perCompleteArr,
                lineStyle: { color: "#FC913A" },
                itemStyle: { color: "#FC913A" },
                yAxisIndex: 2,
              },
            ],
            [
              {
                name: "계획",
                type: "value",
                show: false,
                min: 90000,
                max: 130000,
                interval: 5000,
              },
              {
                name: "실적",
                type: "value",
                show: false,
                min: 90000,
                max: 130000,
                interval: 5000,
              },
              {
                type: "value",
                name: "달성율",
                show: false,
                min: 30,
                max: 125,
                interval: 5,
              },
            ],
            () => handleOverlay("produictive2"),
            isLarge,
            true
          );
        const opt4 = (isLarge) =>
          createHorizontalBarChartProductivity(
            "설비별 부하율 및 가동율(%)",
            sortedUtilRateRes.map((item) => item.mch_name).reverse(),
            [
              {
                type: "bar",
                name: "가동율",
                stack: "Total",
                data: sortedUtilRateRes
                  .map((item) => (item.util_rate * 100).toFixed(1))
                  .reverse(),
                color: "#6A60A9",
                fontSize: 10,
                label: {
                  show: true,
                  position: "right",
                  color: "white",
                },
              },
              {
                type: "bar",
                name: "부하율",
                data: sortedUtilRateRes
                  .map((item) => (item.load_rate * 100 * -1).toFixed(1))
                  .reverse(),
                stack: "Total",
                label: {
                  show: true,
                  position: "left",
                  formatter: function (params) {
                    return Number(params.value) * -1;
                  },
                  color: "white",
                },
                color: "#DEDCEE",
                fontSize: 10,
              },
            ],
            [
              {
                type: "value",
                show: false,
              },
            ],
            () => handleOverlay("produictive4"),
            isLarge,
            true,
            {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
              formatter: (params) => {
                return `${params[0].axisValue} <br />
                            ${params[0].marker} ${params[0].seriesName} : ${
                  params[0].value
                } <br/>
                            ${params[1].marker} ${
                  params[1].seriesName
                } : ${Number(params[1].value * -1)} <br/>`;
              },
              textStyle: {
                fontSize: 10,
              },
            }
          );

        setProOptions({
          proOptions1: opt1(false),
          proOptions2: opt2(false),
          proOptions3: opt3(false),
          proOptions4: opt4(false),
        });

        setProBig({
          proBig1: opt1(true),
          proBig2: opt2(true),
          proBig3: opt3(true),
          proBig4: opt4(true),
        });
        const perCompleteAccArr = performanceRes.data.map((item) =>
          (item.pp_complete_acc * 100).toFixed(1)
        );
        const currentMonth = 6;
        setProductivityTitle([
          {
            id: 1,
            title: "생산계획달성율",
            label1: "누적",
            value1: perCompleteAccArr[currentMonth - 2] + "%",
            label2: "당월",
            value2: perCompleteArr[currentMonth - 1] + "%",
          },
          {
            id: 2,
            label: "설비 부하율",
            value:
              (
                sortedUtilRateRes
                  .map((item) => Number((item.load_rate * 100).toFixed(1)))
                  .reduce((acc, cur) => acc + cur) / sortedUtilRateRes.length
              ).toFixed(1) + "%",
          },
          {
            id: 3,
            label: "설비 가동율",
            value:
              (
                sortedUtilRateRes
                  .map((item) => Number((item.util_rate * 100).toFixed(1)))
                  .reduce((acc, cur) => acc + cur) / sortedUtilRateRes.length
              ).toFixed(1) + "%",
          },
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return {
    loading,
    proOptions,
    proBig,
    productivityTitle,
  };
};
