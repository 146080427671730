import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import themeOptions from './ThemeOptions'

const ThemeComponent = props => {
  // ** Props
  const { settings, children } = props
  const { mode } = settings


  let theme = createTheme(themeOptions({ mode }))

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeComponent
