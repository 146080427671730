import * as s from "../styles";

function Productivity({
  data,
  $labelSize,
  $valueSize,
  $4KlabelSize,
  $4KvalueSize,
}) {
  return (
    <s.Container>
      {data.map((item, idx) => {
        if (!idx) {
          return (
            <s.ProDataContainer key={item.id}>
              <s.Label
                $labelSize={$labelSize}
                $4KlabelSize={$4KlabelSize}
                style={{ fontWeight: "300" }}
              >
                {item.title}
              </s.Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <s.ProductBox>
                  <div>
                    <s.Label style={{ color: "#38d9a9" }}>
                      {item.label1}
                    </s.Label>
                    <s.Label
                      $labelSize={$labelSize}
                      $4KlabelSize={$4KlabelSize}
                    ></s.Label>
                  </div>
                  <s.Value
                    color={"#38d9a9"}
                    $valueSize={$valueSize}
                    $4KvalueSize={$4KvalueSize}
                  >
                    {item.value1}
                  </s.Value>
                </s.ProductBox>
                <s.ProductBox>
                  <div>
                    <s.Label
                      style={{ color: "#ffec99" }}
                      // $labelSize={$labelSize}
                      // $4KlabelSize={$4KlabelSize}
                    >
                      {item.label2}
                    </s.Label>
                    <s.Label
                      $labelSize={$labelSize}
                      $4KlabelSize={$4KlabelSize}
                    ></s.Label>
                  </div>
                  <s.Value
                    color={"#ffec99"}
                    $valueSize={$valueSize}
                    $4KvalueSize={$4KvalueSize}
                  >
                    {item.value2}
                  </s.Value>
                </s.ProductBox>
              </div>
            </s.ProDataContainer>
          );
        } else {
          return (
            <s.DataContainer key={item.id}>
              <s.Label $labelSize={$labelSize} $4KlabelSize={$4KlabelSize}>
                {item.label}
              </s.Label>
              <s.Value
                color={item.color}
                $valueSize={$valueSize}
                $4KvalueSize={$4KvalueSize}
              >
                {item.value}
              </s.Value>
            </s.DataContainer>
          );
        }
      })}
    </s.Container>
  );
}

export default Productivity;
