const breakpoints = () => ({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xl2: 1900,
    xl4: 3200,
    xl4F: 3800
  }
})

export default breakpoints
