// ** MUI Components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// ** Styled Components
const LoginWrapper = styled(Box)(() => ({
  backgroundImage: `url("/images/scheduler_background_2.png")`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default LoginWrapper;