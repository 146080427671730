import React from "react";
import ECharts from "echarts-for-react";
import { Overlay, ChartBox, CloseBtn, Dim } from "./../Graph/styles.js";
import { useChartData } from "../../hooks/useChartData";

function PopupChart({
  onCloseDim,
  handleOverlayClick,
  handlePopOut,
  popShowing,
}) {
  const { chartOptionsMapping } = useChartData();
  return (
    <Dim onClick={onCloseDim}>
      <Overlay onClick={handleOverlayClick}>
        <CloseBtn onClick={handlePopOut}>X</CloseBtn>
        <ChartBox>
          <ECharts
            option={chartOptionsMapping[popShowing]}
            opts={{
              renderer: "svg",
              width: "1500px",
              height: "660px",
            }}
          />
        </ChartBox>
      </Overlay>
    </Dim>
  );
}

export default PopupChart;
