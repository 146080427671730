import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CustomAvatar from "../mui/avartar";
import IconButton from "@mui/material/IconButton";
import {
  MonetizationOnOutlined,
  ManageHistoryOutlined,
  WarehouseOutlined,
  ShoppingCartOutlined,
  DisabledByDefaultOutlined,
  EventAvailableOutlined,
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

const icons = {
  dollar: <MonetizationOnOutlined sx={{ fontSize: "2rem" }} />,
  efficiency: <ManageHistoryOutlined sx={{ fontSize: "2rem" }} />,
  warehouse: <WarehouseOutlined sx={{ fontSize: "2rem" }} />,
  shopping: <ShoppingCartOutlined sx={{ fontSize: "2rem" }} />,
  schedule: <EventAvailableOutlined sx={{ fontSize: "2rem" }} />,
  error: <DisabledByDefaultOutlined sx={{ fontSize: "2rem" }} />,
};

const data = [
  {
    id: 1,
    head: "제품매출액",
    content: "1,018억",
    icon: "dollar",
    color: "primary",
  },
  {
    id: 2,
    head: "PCE",
    content: "12.8%",
    icon: "efficiency",
    color: "info",
  },
  {
    id: 3,
    head: "완제품재고일수",
    content: "32.4일",
    icon: "warehouse",
    color: "#fffb43",
  },
  {
    id: 4,
    head: "원자재 매입액",
    content: "451억",
    icon: "shopping",
    color: "warning",
  },
  {
    id: 5,
    head: "부적합품율",
    content: "1.89%",
    icon: "error",
    color: "error",
  },
  {
    id: 6,
    head: "납기준수율",
    content: "98.3%",
    icon: "schedule",
    color: "success",
  },
];

function KPICards() {
  const { kpiStatus } = useSelector((state) => state.auth);
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    mode: "snap",
    slides: {
      perView: 4,
      // spacing: 10,
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (slider.current) {
        slider.current.next();
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [slider]);

  const nextSlide = () => {
    if (slider.current) {
      slider.current.next();
    }
  };

  const prevSlide = () => {
    if (slider.current) {
      slider.current.prev();
    }
  };

  return (
    <>
      {kpiStatus && (
        <>
          <IconButton
            onClick={prevSlide}
            sx={{
              position: "absolute",
              left: "25%",
              top: "73px",
              zIndex: 10,
            }}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={nextSlide}
            sx={{
              position: "absolute",
              right: "25%",
              top: "73px",
              zIndex: 10,
            }}
          >
            <ArrowForwardIos />
          </IconButton>
          <Card
            sx={{
              width: "40%",
              position: "absolute",
              background: "rgba(47, 51, 73, 0.2) !important",
              top: "40px",
            }}
          >
            <CardContent
              sx={{
                pt: (theme) => `${theme.spacing(7)} !important`,
                pb: (theme) => `${theme.spacing(7.5)} !important`,
                overflow: "hidden",
                opacity: 1,
              }}
            >
              <Grid>
                <Box ref={sliderRef} className="keen-slider">
                  {data.map((sale, index) => (
                    <Grid>
                      <Box
                        key={index}
                        className="keen-slider__slide"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CustomAvatar
                          skin="light"
                          color={sale.color}
                          sx={{ mr: 4, width: 42, height: 42 }}
                        >
                          {icons[sale.icon]}
                        </CustomAvatar>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="h5">{sale.content}</Typography>
                          <Typography variant="body2">{sale.head}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}

export default KPICards;