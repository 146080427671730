import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const DrawerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const DrawerContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledTable = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "tbody tr td": {
    padding: "0.2rem 0.4rem",
  },
  "tbody tr td:first-of-type": {
    color: "#ffffff",
    width: "50%",
  },
  "tbody tr td:last-of-type": {
    color: "#878a99",
  },
}));

const StabilityTable = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "tbody tr td": {
    padding: "0.2rem 0.4rem",
  },
  "tbody tr td:first-of-type": {
    color: "#ffffff",
    width: "50%",
  },
  "tbody tr td:last-of-type": {
    color: "#878a99",
  },
}));

const InfoTable = ({ indicatorData, selectedIndicator, onButtonClick }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (selectedIndicator) {
      setData(indicatorData[selectedIndicator] || {});
    }
  }, [selectedIndicator, indicatorData]);

  const renderStabilityContent = () => {
    const riskPoint = data["위험성 평가 점수"] || "정보없음";
    const riskHigh = data["상"] || "정보없음";
    const riskMedium = data["중"] || "정보없음";
    const riskLow = data["하"] || "정보없음";

    return (
      <>
        <StabilityTable>
          <tbody>
            <tr>
              <td>위험성 평가 점수</td>
              <td>{riskPoint}</td>
            </tr>
          </tbody>
        </StabilityTable>
        <Box sx={{ width: "100%", marginTop: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div id="highIcon">
                  <div id="riskIcon">상</div>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ marginTop: "15px", color: "white", fontSize: "25px" }}
                  >
                    {riskHigh}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div id="mediumIcon">
                  <div id="riskMediumIcon">중</div>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ marginTop: "15px", color: "white", fontSize: "25px" }}
                  >
                    {riskMedium}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div id="lowIcon">
                  <div id="riskIcon">하</div>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ marginTop: "15px", color: "white", fontSize: "25px" }}
                  >
                    {riskLow}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", marginTop: "1rem" }}>
          <Grid item md={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button onClick={onButtonClick}>상세정보</Button>
            </Box>
          </Grid>
        </Box>
      </>
    );
  };

  const renderTableContent = () => {
    if (selectedIndicator === "안정성") {
      return renderStabilityContent();
    } else {
      return (
        <StyledTable>
          <tbody>
            {Object.keys(data).length > 0 ? (
              Object.keys(data).map((key) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{data[key]}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">정보가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      );
    }
  };

  return (
    <>
      <style>
        {`
          #highIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: red;
            width: 40px;
            height: 40px;
            border-radius: 50px;
          }
          #mediumIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: yellow;
            width: 40px;
            height: 40px;
            border-radius: 50px;
          }
          #lowIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: green;
            width: 40px;
            height: 40px;
            border-radius: 50px;
          }
          #riskIcon {
            font-size: 20px;
            color: white;
          }
          #riskMediumIcon {
            font-size: 20px;
            color: black;
          }
        `}
      </style>
      {selectedIndicator && (
        <>
          <Box
            id="drawer-title"
            sx={{ padding: 3, backgroundColor: "#000000" }}
            role="presentation"
          >
            <DrawerTitle>
              <Box
                sx={{ margin: 0, fontSize: "1.2em", fontWeight: 500 }}
              >{`${selectedIndicator} 정보`}</Box>
            </DrawerTitle>
          </Box>
          <Divider />
          <Box
            id="drawer-content"
            sx={{ padding: "0.4rem" }}
            role="presentation"
          >
            <DrawerContent>{renderTableContent()}</DrawerContent>
          </Box>
        </>
      )}
    </>
  );
};

export default InfoTable;
