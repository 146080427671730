import React from "react";
import Content from "./Card/Content";
import ContentTop3 from "./Card/Content/ContentTop3";
import Productivity from "./Card/Content/ContentProductivity";
import Fusion from "./Card/Content/Fusion";

const CardContent = ({ cardType, chartData, fusionData }) => {
  switch (cardType) {
    case "설비 노후화율":
      return (
        <Content
          data={chartData.oldTitleConfig}
          $labelSize="1.3rem"
          $valueSize="2.0rem"
          $4KlabelSize="2rem"
          $4KvalueSize="4rem"
        />
      );
    case "고장도수율":
      return (
        <Content
          data={chartData.brokenTitleConfig}
          $labelSize="1rem"
          $valueSize="1.7rem"
          $4KlabelSize="2rem"
          $4KvalueSize="4rem"
        />
      );
    case "TOP3":
      return (
        <ContentTop3
          initial={chartData.brokenTitleConfig2}
          $labelSize="1.4rem"
          $valueSize="0.62rem"
          $4KlabelSize="2.5rem"
          $4KvalueSize="1.8rem"
        />
      );
    case "일상점검율":
      return (
        <Content
          data={chartData.inspectionTitle}
          $labelSize="0.7rem"
          $valueSize="1.4rem"
          $4KlabelSize="2rem"
          $4KvalueSize="4rem"
        />
      );
    case "보전계획준수율":
      return (
        <Content
          data={chartData.titleConfig}
          $labelSize="0.68rem"
          $valueSize="1.4rem"
          $4KlabelSize="1.6rem"
          $4KvalueSize="3.8rem"
        />
      );
    case "위험성평가 총건수":
      return (
        <Content
          data={chartData.riskTitle}
          $labelSize="0.87rem"
          $valueSize="1.6rem"
          $4KlabelSize="2rem"
          $4KvalueSize="4rem"
        />
      );
    case "생산성":
      return (
        <Productivity
          data={chartData.productivityTitle}
          $labelSize="0.7rem"
          $valueSize="1.4rem"
          $4KlabelSize="2rem"
          $4KvalueSize="4rem"
        />
      );
    case "작업자":
      return (
        <Content
          data={chartData.workerTitle}
          $labelSize="1.2rem"
          $valueSize="1.7rem"
          $4KlabelSize="2rem"
          $4KvalueSize="4rem"
        />
      );
    case "불량":
      return (
        <Content
          data={chartData.failTitle}
          $labelSize="0.9rem"
          $valueSize="1.5rem"
          $4KlabelSize="2rem"
          $4KvalueSize="4rem"
        />
      );
    case "융합":
      return <Fusion data={fusionData} />;
    default:
      return null;
  }
};

export default CardContent;
