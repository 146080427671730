import React from "react";
import * as s from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Content({ data, $4KvalueSize, $4KlabelSize, $labelSize, $valueSize }) {
  return (
    <>
      <s.Container>
        {data.map((item, idx) => (
          <s.DataContainer key={item.id}>
            <s.Label $labelSize={$labelSize} $4KlabelSize={$4KlabelSize}>
              {item.label}
            </s.Label>
            <s.Value
              $valueSize={$valueSize}
              $4KvalueSize={$4KvalueSize}
              color={item.color}
            >
              {item.value}
            </s.Value>
          </s.DataContainer>
        ))}
      </s.Container>
      <s.IconBox>
        <ExpandMoreIcon style={{ fontSize: "1.7rem", color: "white" }} />
      </s.IconBox>
    </>
  );
}

export default Content;
