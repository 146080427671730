import axios from "../api/axios";
import { useDispatch } from "react-redux";
import { setUser } from "../slice/authSlice";
import { getToken, setToken, clearToken } from "../auth/tokenStore";
import API_URL from "../utils/API_URL";

const useRefreshToken = () => {
  const dispatch = useDispatch();

  const refresh = async () => {
    try {
      const response = await axios.post(
        API_URL + "/auth/refresh",
        {},
        { withCredentials: true }
      );
      setToken(response.data.accessToken);
      dispatch(setUser(response.data.user));
      return response.data.accessToken;
    } catch (error) {
      clearToken();
      throw error;
    }
  };

  return refresh;
};

export default useRefreshToken;