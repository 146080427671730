import React from "react";
import * as s from "./styles";

function CarouselContent({
  data,
  $labelSize,
  $valueSize,
  $4KlabelSize,
  $4KvalueSize,
}) {
  return (
    <s.Container>
      {data.map((item, idx) => (
        <s.DataContainer key={item.id}>
          <s.Label $labelSize={$labelSize} $4KlabelSize={$4KlabelSize}>
            {item.label}
          </s.Label>
          <s.Value
            color={item.color}
            $valueSize={$valueSize}
            $4KvalueSize={$4KvalueSize}
          >
            {item.value}
          </s.Value>
        </s.DataContainer>
      ))}
    </s.Container>
  );
}

export default CarouselContent;
