import * as s from "./styles";

function Fusion({ data }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "5px",
      }}
    >
      <span
        style={{
          fontWeight: "normal",
          fontSize: "1.2rem",
          marginLeft: "10px",
          textAlign: "center",
        }}
      >
        융합지표
      </span>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {data?.map((item) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              fontSize: "0.8rem",
              fontWeight: "normal",
            }}
            key={item.id}
          >
            <s.Label color1={item.color1}>{item.label1}</s.Label>
            <span>&</span>
            <s.Label2 color2={item.color2}>{item.label2}</s.Label2>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Fusion;
