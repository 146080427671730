import { useState, useEffect } from "react";
import API_URL from "../utils/API_URL";
import axios from "axios";

export const useRiskData = () => {
  const [loading, setLoading] = useState(false);
  const [factor, setFactor] = useState([]);
  const [register1, setRegister] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(API_URL + "/data/getRiskFactor");
        const registerAxios = await axios.get(
          API_URL + "/data/getRiskRegister"
        );
        setFactor(res.data);
        setRegister(registerAxios.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return {
    loading,
    setFactor,
    setRegister,
    factor,
    register1,
  };
};
