import React from "react";
import * as s from "./styles";
import CarouselContent from "./CarouselContent";
import { useRiskMaintenance } from "../../../hooks/useRiskMaintenance";
import { useMaintenanceResultExpenseData } from "../../../hooks/useMaintenanceResultExpenseData";
import { useMasterAssetDataForGraph } from "../../../hooks/useMasterAssetDataForGraph";
import { useBrokenData } from "../../../hooks/useBrokenData";
import { useDataInspection } from "../../../hooks/useDataInspection";
import Top3 from "./CarouselContent/Top3";
import Productivity from "./CarouselContent/Productivity";
import { useProductivityData } from "../../../hooks/useProductivityData";
import { useSelector } from "react-redux";
import { useWorkerData } from "../../../hooks/useWorkerData";
import { useFailureData } from "../../../hooks/useFailureData";
import Fusion from "./CarouselContent/Fusion";

const fusionData = [
  {
    id: 1,
    label1: "설비사용율",
    color1: "#E8FFE6",
    label2: "설비불량율",
    color2: "#E0F8FF",
  },
  {
    id: 2,
    label1: "설비가동율",
    color1: "#E8FFE6",
    label2: "UPH",
    color2: "#E0F8FF",
  },
  {
    id: 3,
    label1: "일상점검율",
    color1: "#E8FFE6",
    label2: "고장율",
    color2: "#E0F8FF",
  },
  {
    id: 4,
    label1: "위험지수",
    color1: "#E8FFE6",
    label2: "UPH",
    color2: "#E0F8FF",
  },
];
// 클릭 전 Carousel의 콘텐츠 쪽.
function Card({ cardsRef, cards, handleCardClick }) {
  const { riskTitle } = useRiskMaintenance();
  const { titleConfig } = useMaintenanceResultExpenseData();
  const { oldTitleConfig } = useMasterAssetDataForGraph();
  const { brokenTitleConfig, brokenTitleConfig2 } = useBrokenData();
  const { inspectionTitle } = useDataInspection();
  const { productivityTitle } = useProductivityData();
  const { workerTitle } = useWorkerData();
  const { failTitle } = useFailureData();
  const { chattingStatus } = useSelector((state) => state.auth);
  const isUnityChattingOpend =
    chattingStatus.type === "Meta" && chattingStatus.status === "1";
  return (
    <s.CardsContainer $isUnityChattingOpend={isUnityChattingOpend}>
      <s.Cards ref={cardsRef}>
        {cards.map((card, index) => (
          <s.Card
            $isUnityChattingOpend={isUnityChattingOpend}
            key={card.id}
            onClick={() => handleCardClick(card)}
          >
            {card.content === "설비 노후화율" && (
              <CarouselContent
                data={oldTitleConfig}
                $labelSize="1.4rem"
                $valueSize="2.6rem"
                $4KlabelSize="2.3rem"
                $4KvalueSize="4.8rem"
              />
            )}
            {card.content === "고장도수율" && (
              <CarouselContent
                data={brokenTitleConfig}
                $labelSize="1.4rem"
                $valueSize="2.6rem"
                $4KlabelSize="2.3rem"
                $4KvalueSize="4.8rem"
              />
            )}
            {card.content === "TOP3" && (
              <Top3
                initial={brokenTitleConfig2}
                $labelSize="1.3rem"
                $valueSize="1.0rem"
                $4KlabelSize="2.3rem"
                $4KvalueSize="2.0rem"
              />
            )}
            {card.content === "일상점검율" && (
              <CarouselContent
                data={inspectionTitle}
                $labelSize="1.1rem"
                $valueSize="1.6rem"
                $4KlabelSize="2.0rem"
                $4KvalueSize="3.8rem"
              />
            )}
            {card.content === "작업자" && (
              <CarouselContent
                data={workerTitle}
                $labelSize="1.3rem"
                $valueSize="1.8rem"
                $4KlabelSize="2.0rem"
                $4KvalueSize="3.8rem"
              />
            )}
            {card.content === "보전계획준수율" && (
              <CarouselContent
                data={titleConfig}
                $labelSize="1rem"
                $valueSize="1.51rem"
                $4KlabelSize="1.8rem"
                $4KvalueSize="3.8rem"
              />
            )}
            {card.content === "위험성평가 총건수" && (
              <CarouselContent
                data={riskTitle}
                $labelSize="1.2rem"
                $valueSize="1.9rem"
                $4KlabelSize="2.2rem"
                $4KvalueSize="4.6rem"
              />
            )}
            {card.content === "생산성" && (
              <Productivity
                data={productivityTitle}
                $labelSize="1.2rem"
                $valueSize="1.6rem"
                $4KlabelSize="1.8rem"
                $4KvalueSize="3.8rem"
              />
            )}
            {card.content === "불량" && (
              <CarouselContent
                data={failTitle}
                $labelSize="1.3rem"
                $valueSize="1.6rem"
                $4KlabelSize="1.8rem"
                $4KvalueSize="3.8rem"
              />
            )}
            {card.content === "융합" && (
              <Fusion
                data={fusionData}
                $labelSize="1.3rem"
                $valueSize="1.6rem"
                $4KlabelSize="1.8rem"
                $4KvalueSize="3.8rem"
              />
            )}
          </s.Card>
        ))}
      </s.Cards>
    </s.CardsContainer>
  );
}

export default Card;
