import { Box, Dialog, DialogContent, DialogActions, Button, Typography, Pagination } from '@mui/material';
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';

const columns = [
  {
    flex: 0.1,
    minWidth: 140,
    field: 'risk_factor_code',
    headerName: '위험 코드',
    headerAlign: 'center',
    align: 'center',
  },
  {
    flex: 0.1,
    minWidth: 140,
    field: 'risk_cause',
    headerName: '위험 원인',
    headerAlign: 'center',
    align: 'center',
  },
  {
    flex: 0.35,
    minWidth: 140,
    field: 'risk_description',
    headerName: '위험 상황',
    headerAlign: 'center',
    align: 'center',
  },
  {
    flex: 0.1,
    minWidth: 140,
    field: 'risk_danger_level',
    headerName: '위험성 평가',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      switch (params.value) {
        case 3:
          return (
            <div id="gridHighIcon">
              <div id="gridRiskIcon">상</div>
            </div>
          );
        case 2:
          return (
            <div id="gridMediumIcon">
              <div id="gridRiskMediumIcon">중</div>
            </div>
          );
        case 1:
          return (
            <div id="gridLowIcon">
              <div id="gridRiskIcon">하</div>
            </div>
          );
        default:
          return '미평가';
      }
    },
  },
  {
    flex: 0.35,
    minWidth: 140,
    field: 'risk_measure',
    headerName: '개선 대책',
    headerAlign: 'center',
    align: 'center',
  },
];

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <>
      <Typography color="textSecondary" flexGrow={1}>
        {`Showing ${page * 10 + 1} to ${Math.min((page + 1) * 10, apiRef.current.state.pagination.rowCount)} of ${apiRef.current.state.pagination.rowCount} entries`}
      </Typography>
      <Pagination
        variant='outlined'
        shape="rounded"
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(_, newPage) => apiRef.current.setPage(newPage - 1)}
        showFirstButton
        showLastButton
      />
    </>
  );
};

const RiskTableDialog = ({ open, onClose, data, assetInfo }) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  // const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   if (data.length > 0) {
  //     setLoaded(true);
  //   }
  // }, [data]);

  const handleDialogClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <>
      <style>
        {`
          #gridHighIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: red;
            width: 30px;
            height: 30px;
            border-radius: 50px;
          }
          #gridMediumIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: yellow;
            width: 30px;
            height: 30px;
            border-radius: 50px;
          }
          #gridLowIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: green;
            width: 30px;
            height: 30px;
            border-radius: 50px;
          }
          #gridRiskIcon {
            font-size: 15px;
            color: white;
          }
          #gridRiskMediumIcon {
            font-size: 15px;
            color: black;
          }
        `}
      </style>
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        scroll="body"
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            onClose();
          }
        }}
        sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
      >
        <DialogContent
          sx={{
            px: (theme) => [`${theme.spacing(5)} !important`, `${theme.spacing(10)} !important`],
            py: (theme) => [`${theme.spacing(8)} !important`, `${theme.spacing(10)} !important`]
          }}
        >
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Typography variant="h3" sx={{ mb: 3, color: '#ced4da' }}>
              위험성 평가 상세 정보 - {assetInfo}
            </Typography>
          </Box>
          {/* {loaded ? ( */}
            <DataGrid
              autoHeight
              getRowHeight={() => 52}
              pagination
              rows={data}
              columns={columns}
              // checkboxSelection
              disableRowSelectionOnClick
              pageSizeOptions={[10, 25, 50]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              slots={{
                pagination: CustomPagination,
              }}
              sx={{
                '& .MuiDataGrid-container--top [role="row"], .MuiDataGrid-container--bottom [role="row"]': {
                  background: 'none !important',
                },
                '& .MuiDataGrid-cell': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
            />
          {/* ) : (
            <Typography>Loading...</Typography>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RiskTableDialog;