import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_URL from "../utils/API_URL";

const initialState = {
  user: null,
  isAuthenticated: false,
  assetCode: "",
  storage: [],
  graphStatus: true,
  kpiStatus: true,
  month: 1,
  popStatus: false,
  popShowing: "",
  chattingStatus: {
    type: "",
    status: null,
  },
};

export const fetchStorage = createAsyncThunk("auth/fetchStorage", async () => {
  const res = await axios.get(API_URL + "/data/getMasterAsset");
  const final = [...new Set(res.data[0].map((item) => item.asset_code))];
  return final;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setAssetCode(state, action) {
      state.assetCode = action.payload;
    },
    setGraphStatus(state, action) {
      state.graphStatus = action.payload;
    },
    setMonth(state, action) {
      state.month = action.payload;
    },
    setKpiStatus(state, action) {
      state.kpiStatus = action.payload;
    },
    setPopStatus(state, action) {
      state.popStatus = action.payload;
    },
    setPopShowing(state, action) {
      state.popShowing = action.payload;
    },
    setChattingStatus(state, action) {
      state.chattingStatus = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorage.fulfilled, (state, action) => {
      state.storage = action.payload;
    });
  },
});

export const {
  setUser,
  setAssetCode,
  setGraphStatus,
  setKpiStatus,
  setMonth,
  setPopStatus,
  setPopShowing,
  logout,
  setChattingStatus,
} = authSlice.actions;
export default authSlice.reducer;
