import axios from "axios";
import API_URL from "../utils/API_URL";
import { useState, useEffect } from "react";

export const useGetMasterEmployee = () => {
  const [employeeData, setEmployeeData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(API_URL + "/data/getMasterEmployee");
        setEmployeeData(res.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);
  return {
    employeeData,
  };
};
