import { useRiskMaintenance } from "./useRiskMaintenance";
import { useMaintenanceResultExpenseData } from "./useMaintenanceResultExpenseData";
import { useMasterAssetDataForGraph } from "./useMasterAssetDataForGraph";
import { useWorkerData } from "./useWorkerData";
import { useProductivityData } from "./useProductivityData";
import { useFailureData } from "./useFailureData";
import { useFusionMatrix } from "./useFusionMatrix";
import { useBrokenData } from "./useBrokenData";
import { useDataInspection } from "./useDataInspection";

export function useChartData() {
  const { riskTitle, riskBigs } = useRiskMaintenance();
  const { riskbig1, riskbig2, riskbig3, riskbig4 } = riskBigs;
  const { titleConfig, bigOptions } = useMaintenanceResultExpenseData();
  const { six, facility, expense, sixEach } = bigOptions;
  const { oldTitleConfig, bigOld } = useMasterAssetDataForGraph();
  const { old1, old2, old3 } = bigOld;
  const { brokenTitleConfig, brokenTitleConfig2, brokenBig } = useBrokenData();
  const {
    topBig1,
    topBig2,
    topBig3,
    brokenBig1,
    brokenBig2,
    brokenBig4,
    brokenBigMatrix,
  } = brokenBig;
  const { inspectionTitle, inspectionBig } = useDataInspection();
  const { inspectionBig1, inspectionBig2, inspectionBig3, inspectionBig4 } =
    inspectionBig;
  const { workerTitle, workerBig } = useWorkerData();
  const { workerBig1, workerBig2, workerBig3, workerBig4 } = workerBig;
  const { productivityTitle, proBig } = useProductivityData();
  const { proBig1, proBig3, proBig2, proBig4 } = proBig;
  const { failBig, failTitle } = useFailureData();
  const { failBig1, failBig2, failBig3, failBig4 } = failBig;
  const { fusionBig1, fusionBig2, fusionBig3, fusionBig4 } = useFusionMatrix();
  return {
    riskTitle,
    riskbig1,
    riskbig2,
    riskbig3,
    riskbig4,
    titleConfig,
    six,
    facility,
    expense,
    sixEach,
    oldTitleConfig,
    old1,
    old2,
    old3,
    brokenTitleConfig,
    brokenTitleConfig2,
    topBig1,
    topBig2,
    topBig3,
    brokenBig1,
    brokenBig2,
    brokenBig4,
    brokenBigMatrix,
    inspectionTitle,
    inspectionBig1,
    inspectionBig2,
    inspectionBig3,
    inspectionBig4,
    workerTitle,
    workerBig1,
    workerBig2,
    workerBig3,
    workerBig4,
    productivityTitle,
    proBig1,
    proBig3,
    proBig2,
    proBig4,
    failBig1,
    failBig2,
    failBig3,
    failBig4,
    failTitle,
    fusionBig1,
    fusionBig2,
    fusionBig3,
    fusionBig4,
    chartOptionsMapping: {
      produictive1: proBig1 || {},
      produictive2: proBig3 || {},
      produictive3: proBig2 || {},
      produictive4: proBig4 || {},
      inspection1: inspectionBig1 || {},
      inspection2: inspectionBig2 || {},
      inspection3: inspectionBig3 || {},
      inspection4: inspectionBig4 || {},
      top31: topBig1 || {},
      top32: topBig2 || {},
      top33: topBig3 || {},
      broken1: brokenBig1 || {},
      broken2: brokenBig2 || {},
      brokenMatrix: brokenBigMatrix || {},
      broken4: brokenBig4 || {},
      old1: old1 || {},
      old2: old2 || {},
      old3: old3 || {},
      risk1: riskbig1 || {},
      risk2: riskbig2 || {},
      risk3: riskbig3 || {},
      risk4: riskbig4 || {},
      expense1: six || {},
      expense2: facility || {},
      expense3: expense || {},
      expense4: sixEach || {},
      worker1: workerBig1 || {},
      worker2: workerBig2 || {},
      worker3: workerBig3 || {},
      worker4: workerBig4 || {},
      fail1: failBig1 || {},
      fail2: failBig2 || {},
      fail3: failBig3 || {},
      fail4: failBig4 || {},
      fusion1: fusionBig1 || {},
      fusion2: fusionBig2 || {},
      fusion3: fusionBig3 || {},
      fusion4: fusionBig4 || {},
    },
  };
}
