import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import { createBarChartOptions } from "../utils/chartConfig";

const colorSet = ["red", "yellow", "green"];
const colorSet2 = ["#6A60A9", "#DEDCEE", "#FFC952"];

const createRiskMaintenanceChartOptions = (
  title,
  xAxisData,
  series,
  handleOverlay,
  isLarge = false
) => {
  return createBarChartOptions(
    title,
    xAxisData,
    series,
    { type: "value", show: false },
    handleOverlay,
    isLarge
  );
};

export const useRiskMaintenance = () => {
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };

  const [loading, setLoading] = useState(false);
  const [riskTitle, setRiskTitle] = useState([]);
  const [riskOptions, setRiskOptions] = useState({});
  const [riskBigs, setRiskBigs] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(API_URL + "/data/getRiskMaintenance");
        const data = res.data;
        const filteredData = data.filter(
          (item) => item.risk_measure_expect !== "0000-00"
        );
        const causeNameSet = [...new Set(data.map((item) => item.risk_cause))];
        const expectDataSet = [
          ...new Set(filteredData.map((item) => item.risk_measure_expect)),
        ];
        const dangerLevelSet = [
          ...new Set(data.map((item) => item.risk_danger_level)),
        ].sort((a, b) => a - b);
        const measureTypeSet = [
          ...new Set(
            data
              .map((item) => item.risk_measure_type)
              .filter((item) => item.length)
          ),
        ];
        const titleDangerLevel = dangerLevelSet.reduce((acc, level) => {
          acc[`danger${level}`] = data.filter(
            (item) => item.risk_danger_level === level
          ).length;
          return acc;
        }, {});
        titleDangerLevel.dangerEntire = data.length;
        const option1Series = dangerLevelSet.map((level) => ({
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: (params) => (params.value === 0 ? "" : params.value),
            color: "white",
            fontSize: 10,
          },
          emphasis: { focus: "series" },
          data: expectDataSet.map(
            (date) =>
              data.filter(
                (item) =>
                  item.risk_register_date === date &&
                  item.risk_danger_level === level
              ).length
          ),
          name: level === 1 ? "상" : level === 2 ? "중" : "하",
          color: colorSet[level - 1],
        }));

        const createSeries = (dataSet, filterKey, valueKey, colorSet) => {
          return dataSet.map((level, index) => ({
            name: level === 1 ? "상" : level === 2 ? "중" : "하",
            type: "bar",
            stack: "total",
            emphasis: { focus: "series" },
            data: causeNameSet.map(
              (cause) =>
                data.filter(
                  (item) =>
                    item[filterKey] === cause && item[valueKey] === level
                ).length
            ),
            color: colorSet[index],
          }));
        };
        const createSeriesForSeries3 = (xAxis, dataSet, colorSet) => {
          return dataSet.map((level, index) => ({
            name: dataSet[index],
            type: "bar",
            stack: "total",
            emphasis: { focus: "series" },
            data: xAxis.map(
              (date) =>
                data.filter(
                  (item) =>
                    item.risk_measure_expect === date &&
                    item.risk_measure_type === level
                ).length
            ),
            color: colorSet[index],
          }));
        };
        const options1 = createBarChartOptions(
          "월별 위험성평가 건수(건)",
          expectDataSet,
          option1Series,
          {
            type: "value",
            show: false,
          },
          () => handleOverlay("risk1"),
          false,
          false
        );
        const riskbig1 = createBarChartOptions(
          "월별 위험성평가 건수(건)",
          expectDataSet,
          option1Series,
          {
            type: "value",
            show: false,
          },
          () => handleOverlay("risk1"),
          true,
          false
        );
        const options1Series = createSeries(
          dangerLevelSet,
          "risk_cause",
          "risk_danger_level",
          colorSet
        );
        const options2 = createRiskMaintenanceChartOptions(
          "위험원인별 평가 건수(건)",
          causeNameSet,
          options1Series,
          () => handleOverlay("risk2")
        );
        const riskbig2 = createRiskMaintenanceChartOptions(
          "위험원인별 평가 건수(건)",
          causeNameSet,
          options1Series,
          () => handleOverlay("risk2"),
          true
        );
        const option3Series = createSeriesForSeries3(
          expectDataSet,
          measureTypeSet,
          colorSet2
        );
        const options3 = createRiskMaintenanceChartOptions(
          "월별개선대책실시 건수(건)",
          expectDataSet,
          option3Series,
          () => handleOverlay("risk3"),
          false,
          true
        );
        const riskbig3 = createRiskMaintenanceChartOptions(
          "월별개선대책실시 건수(건)",
          expectDataSet,
          option3Series,
          () => handleOverlay("risk3"),
          true,
          true
        );
        const option4Data = expectDataSet.map((date) => {
          const target = data.filter(
            (item) => item.risk_measure_expect === date
          );
          const success = target.filter(
            (item) =>
              item.risk_measure_expect_full >= item.risk_measure_end_full
          );
          return ((success.length / target.length) * 100).toFixed(1);
        });

        const options4 = createBarChartOptions(
          "월별개선대책 준수율(%)",
          expectDataSet,
          [
            {
              data: option4Data,
              type: "line",
              color: "#FC913A",
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("risk4"),
          false,
          false
        );

        const riskbig4 = createBarChartOptions(
          "월별개선대책 준수율(%)",
          expectDataSet,
          [
            {
              data: option4Data,
              type: "line",
              color: "#FC913A",
            },
          ],
          { type: "value", show: false },
          () => handleOverlay("risk4"),
          true,
          false
        );
        setRiskOptions({
          options1,
          options2,
          options3,
          options4,
        });
        setRiskBigs({
          riskbig1,
          riskbig2,
          riskbig3,
          riskbig4,
        });
        const riskTitleArray = [
          {
            id: 1,
            label: "위험성평가 총건수",
            value: titleDangerLevel.dangerEntire,
            color: "white",
          },
          ...dangerLevelSet.map((level, index) => ({
            id: index + 2,
            label: level === 1 ? "상" : level === 2 ? "중" : "하",
            value: titleDangerLevel[`danger${level}`],
            color: colorSet[index],
          })),
          {
            id: 5,
            label: "위험성평가 실행율",
            value: "93.8%",
            color: "white",
          },
        ];
        setRiskTitle(riskTitleArray);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return {
    loading,
    riskTitle,
    riskOptions,
    riskBigs,
  };
};
