import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import {
  createBarChartOptions,
  createHorizontalBarChartOptions,
} from "../utils/chartConfig";

const colorSet = ["#6A60A9", "#DEDCEE"];

function groupByMaPlanSix(facilityData) {
  const groupedData = [];
  facilityData.data.forEach((item) => {
    const existingGroup = groupedData.find(
      (group) => group.ma_plan_six === item.ma_plan_six
    );

    if (existingGroup) {
      existingGroup.ma_planset_man += item.ma_planset_man;
      existingGroup.ma_result_man += item.ma_result_man;
    } else {
      groupedData.push({
        ma_plan_six: item.ma_plan_six,
        ma_planset_man: item.ma_planset_man,
        ma_result_man: item.ma_result_man,
      });
    }
  });
  return groupedData;
}

function countResultDateLessThanOrEqualPlansetDate(facilityData) {
  return facilityData.data.filter(
    (item) => new Date(item.ma_result_date) <= new Date(item.ma_planset_date)
  ).length;
}

const groupByAndSum = (data, key, valueKey) => {
  return Object.values(
    data.reduce((acc, item) => {
      const group = item[key];
      if (!acc[group]) {
        acc[group] = 0;
      }
      acc[group] += item[valueKey];
      return acc;
    }, {})
  );
};

export const useMaintenanceResultExpenseData = () => {
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };

  const [assetData, setAssetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expenseOptions, setExpenseOptions] = useState({});
  const [bigOptions, setBigOptions] = useState({});
  const [titleConfig, setTitleConfig] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(
          API_URL + "/data/getMaintenanceResultExpense"
        );
        const facilityData = await axios.get(
          API_URL + "/data/getMaintenanceWorkForEachFacility"
        );

        const xAxisData = [
          ...new Set(res.data.map((item) => item.asset_name)),
        ];
        const result = Object.entries(
          res.data.reduce((acc, item) => {
            const key = `${item.asset_name}-${item.ma_expend_type}`;
            acc[key] = (acc[key] || 0) + item.ma_expend_sum;
            return acc;
          }, {})
        ).map(([key, value]) => {
          const [asset_name, ma_expend_type] = key.split("-");
          return { asset_name, ma_expend_type, ma_expend_sum: value };
        });
        const typeSet = [
          ...new Set(res.data.map((item) => item.ma_expend_type)),
        ];

        let seriesData = typeSet.map((type, index) => ({
          name: type,
          type: "bar",
          stack: "total",
          emphasis: { focus: "series" },
          data: result
            .filter((item) => item.ma_expend_type === type)
            .map((item) => item.ma_expend_sum),
          color: colorSet[index % colorSet.length],
        }));

        const expenseOptions = createBarChartOptions(
          "설비별 보전비용(원)",
          xAxisData,
          seriesData,
          { type: "value", show: false },
          () => handleOverlay("expense3"),
          false,
          true
        );

        const big3 = createBarChartOptions(
          "설비별 보전비용(원)",
          xAxisData,
          seriesData,
          { type: "value", show: false },
          () => handleOverlay("expense3"),
          true,
          true
        );

        setExpenseOptions((prevOptions) => ({
          ...prevOptions,
          expense: expenseOptions,
        }));
        setBigOptions((prevBigOptions) => ({
          ...prevBigOptions,
          expense: big3,
        }));

        const facilityDataNameSet = [
          ...new Set(facilityData.data.map((item) => item.asset_name)),
        ];

        const titleConfigData = [
          {
            id: 1,
            label: "보전계획준수율",
            value: `${Math.round(
              (countResultDateLessThanOrEqualPlansetDate(facilityData) /
                facilityData.data.length) *
                100
            )}%`,
            color: "#C5F6FA",
          },
          {
            id: 2,
            label: "보전수행건수",
            value: `${facilityData.data.length} / 24`,
            color: "white",
          },
          {
            id: 3,
            label: "보전수행공수",
            value: `${facilityData.data.reduce(
              (sum, item) => sum + item.ma_result_man,
              0
            )}`,
            color: "white",
          },
          {
            id: 4,
            label: "보전비용",
            value: `${res.data
              .reduce((sum, item) => sum + item.ma_expend_sum, 0)
              .toLocaleString()}원`,
            color: "white",
          },
        ];
        setTitleConfig(titleConfigData);
        const names = ["보전계획공수", "보전수행공수"];
        let facilitySeries = names.map((name, index) => ({
          name,
          type: "bar",
          data: groupByAndSum(
            facilityData.data,
            "asset_name",
            index === 0 ? "ma_planset_man" : "ma_result_man"
          ),
          color: colorSet[index],
        }));
        const facilityOptions = createHorizontalBarChartOptions(
          "설비별 보전공수 계획 vs 실적(건)",
          facilityDataNameSet,
          facilitySeries,
          { type: "value", show: false },
          () => handleOverlay("expense2"),
          false,
          true
        );
        const big2 = createHorizontalBarChartOptions(
          "설비별 보전공수 계획 vs 실적(건)",
          facilityDataNameSet,
          facilitySeries,
          { type: "value", show: false },
          () => handleOverlay("expense2"),
          true,
          true
        );
        setExpenseOptions((prevOptions) => ({
          ...prevOptions,
          facility: facilityOptions,
        }));
        setBigOptions((prevBigOptions) => ({
          ...prevBigOptions,
          facility: big2,
        }));

        const sixNameSet = [
          ...new Set(facilityData.data.map((item) => item.ma_plan_six)),
        ];
        const sixGroup = groupByMaPlanSix(facilityData);

        let sixSeries = names.map((name, index) => ({
          name,
          type: "bar",
          data: sixGroup.map((item) =>
            index === 0 ? item.ma_planset_man : item.ma_result_man
          ),
          color: colorSet[index],
        }));
        const sixConfigOptions = createBarChartOptions(
          "설비6계통 보전공수 계획 vs 실적(건)",
          sixNameSet,
          sixSeries,
          { type: "value", show: false },
          () => handleOverlay("expense1"),
          false,
          true
        );

        const big1 = createBarChartOptions(
          "설비6계통 보전공수 계획 vs 실적(건)",
          sixNameSet,
          sixSeries,
          { type: "value", show: false },
          () => handleOverlay("expense1"),
          true,
          true
        );

        setExpenseOptions((prevOptions) => ({
          ...prevOptions,
          six: sixConfigOptions,
        }));
        setBigOptions((prevBigOptions) => ({ ...prevBigOptions, six: big1 }));

        const sixEachNameSet = [
          ...new Set(facilityData.data.map((item) => item.ma_plan_six)),
        ];
        const sixEachData = [];
        for (let i = 0; i < sixNameSet.length; i++) {
          const filtered = facilityData.data.filter(
            (item) => item.ma_plan_six === sixNameSet[i]
          );
          sixEachData.push(filtered.length);
        }
        const sixEachSeries = [
          {
            name: "보전수행건수",
            type: "bar",
            data: sixEachData,
            color: colorSet[0],
          },
        ];
        const sixEachOptions = createBarChartOptions(
          "설비6계통별 보전수행건수",
          sixEachNameSet,
          sixEachSeries,
          { type: "value", show: false },
          () => handleOverlay("expense4")
        );
        const big4 = createBarChartOptions(
          "설비6계통별 보전수행건수",
          sixEachNameSet,
          sixEachSeries,
          { type: "value", show: false },
          () => handleOverlay("expense4"),
          true
        );
        setExpenseOptions((prevOptions) => ({
          ...prevOptions,
          sixEach: sixEachOptions,
        }));
        setBigOptions((prevBigOptions) => ({
          ...prevBigOptions,
          sixEach: big4,
        }));
        setAssetData(res.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return {
    assetData,
    loading,
    expenseOptions,
    bigOptions,
    titleConfig,
  };
};
